import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/24/outline'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { functions, auth, analytics } from '../firebase';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios'; 


const stripePromise = loadStripe('pk_live_51P1OQS04pj69RFSAkqtf17RNNT1bXKytGBgLTeIrKHH5lNtWqkASpbbJmBN8GEc83FzYfYdCMeJQAkZxP8aVr3Vt00IOumeExQ');


interface UpgradePopUpProps { open: boolean;
  onClose: () => void;
}

const UpgradePopUp: React.FC<UpgradePopUpProps> = ({ open, onClose }) => {
    const handleSubscriptionPurchase = async () => {
        analytics.logEvent('upgrade_popup_upgrade', {
            button_name: 'upgrade_button',
          });
        const user = auth.currentUser;
        if (!user) {
          console.error('User not authenticated');
          // TODO: Handle user not authenticated error
          return;
        }
    
        const userId = user.uid;
        console.log('User Id:', userId);
    
        try {
          const idToken = await user.getIdToken(true);
          const response = await axios.post(
            'https://us-central1-avery-59f28.cloudfunctions.net/createCheckoutSession',
            { userId },
            { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${idToken}` } }
          );
    
          const data = response.data;
          if (data.sessionUrl) {
            window.location.href = data.sessionUrl;
          } else {
            throw new Error('Invalid response from server');
          }
        } catch (error) {
          console.error('Error creating Checkout Session:', error);
        }
      };

  const cancelButtonRef = useRef(null);

    function setOpen(value: boolean): void {
        throw new Error('Function not implemented.');
    }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-yellow-100">
                    <ExclamationTriangleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Upgrade to get access
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        By clicking upgrade, you will be redirected to Stripe where you can complete the upgrade.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    onClick={handleSubscriptionPurchase}
                  >
                    Upgrade
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={onClose}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default UpgradePopUp; 
