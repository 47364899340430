import React, { Fragment, useState, useEffect } from 'react'
import { RadioGroup } from '@headlessui/react'
import { Dialog } from '@headlessui/react'
import {
  ClockIcon,
  Bars3Icon,
  DocumentCheckIcon,
  ArrowUpTrayIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { CheckIcon } from '@heroicons/react/20/solid'
import { CreditCardIcon, NewspaperIcon, UserPlusIcon } from '@heroicons/react/24/outline'
import logo from '../assets/designreviewlogo.png';
import mainScreen from '../assets/landingpage/newmainscreen.png';
import explanatoryImage from '../assets/landingpage/handsmainscreen.png';
import spotifyScreen from '../assets/spotifyscreenshot.png';
import exampleScreen from '../assets/examplescreen.jpg';
import { auth, analytics, googleAuthProvider, functions } from '../firebase';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { User } from '@firebase/auth-types';
import { Transition } from '@headlessui/react'
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import { SparklesIcon } from '@heroicons/react/20/solid'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'
import logo1 from '../assets/landingpage/Google_2015_logo.svg.webp';
import logo2 from '../assets/landingpage/Spotify_logo_with_text.svg.png';
import logo3 from '../assets/landingpage/adobelogo.webp';
import logo4 from '../assets/landingpage/PayPal.svg.png';
import logo5 from '../assets/landingpage/hsbclogo.svg.png';
import { Disclosure } from '@headlessui/react'
import { StarIcon } from '@heroicons/react/20/solid'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import person1 from '../assets/landingpage/hank.jpg';
import person2 from '../assets/landingpage/vinita.jpg';
import person3 from '../assets/landingpage/sagar.jpg';
import person4 from '../assets/landingpage/jean.jpg';
import person5 from '../assets/landingpage/fiona.jpg';
import person6 from '../assets/landingpage/hampus.jpg';


import spotifyExample from '../assets/landingpage/spotifyexample.png';
import appleExample from '../assets/landingpage/appleexample.png';
import calmExample from '../assets/landingpage/calmexample.png';
import epidemicExample from '../assets/landingpage/epidemicexample.png';

const frequencies = [
  { value: 'monthly', label: 'Monthly', priceSuffix: '/month' },
  { value: 'annually', label: 'Annually', priceSuffix: '/year' },
]

const paidTiers = [
  {
    name: 'Solo',
    id: 'tier-freelancer',
    href: '#',
    price: { monthly: '$29', annually: '$144' },
    description: 'The essentials to provide your best work. Ideal for freelancers and individuals.',
    features: [
      '15 design reviews per month',
      'Single user access',
    ],
    mostPopular: true,
    cta: 'Buy plan',
  },
  {
    name: 'Startup',
    id: 'tier-startup',
    href: '#',
    price: { monthly: '$149', annually: '$288' },
    description: 'Powerful design optimization for startups focused on growth and success.',
    features: [
      '50 design reviews per month',
      'Up to 5 user accounts',
      'Priority support',
    ],
    mostPopular: false,
    cta: 'Coming soon',
  },
  {
    name: 'Enterprise',
    id: 'tier-enterprise',
    href: '#',
    price: { monthly: '$699', annually: '$288' },
    description: 'Tailored solutions and dedicated support for companys unique needs.',
    features: [
      'Unlimited design reviews',
      'Up to 50 user accounts',
      'Includes custom brand consistency check',
      'Priority support',
      'Customized onboarding and training sessions',
    ],
    mostPopular: false,
    cta: 'Coming soon',
  },
]




const navigation = [
  { name: 'Pricing', href: '#pricing' },
  { name: 'Examples', href: '#examples' },
  { name: 'Company', href: 'https://peachyinnovation.com', target: '_blank' },

]



{/*Example code*/}
  const examples = [
    {
      name: 'Spotify Desktop UI',
      image: spotifyExample,
      method: 'Heuristic evaluation',
      reportHeadline: 'UX Evaluation of Music Streaming Service',
      reportIntro: 'This is a heuristic evaluation of 1 screenshot from what appears to be a music streaming service.',
      reportStrenghts: `The interface has a clear and organized layout, with distinct sections for "Jump back in" and "Recently played" content, making it easy for users to find and resume listening to their favorite music. 2) Album artwork is prominently displayed, providing visual cues to help users quickly identify and select the content they want to listen to. 3) The "Show all" links suggest that users can easily access more content in each section, enhancing discoverability. 4) The player controls at the bottom of the screen are readily accessible, allowing users to control playback without navigating away from this screen.`,
      reportWeaknesses: `For new users, it may not be immediately clear how to search for specific songs, albums, or artists, as no search bar is visible in this screenshot. 2) The purpose of the "All" tab at the top is not entirely clear, which could potentially confuse first-time users. 3) There is no obvious way to create playlists or add songs to a library from this screen, which could be an important feature for music app users. 4) The interface appears quite text-heavy, which might overwhelm some users and make it harder to quickly scan and find desired content.`,
      reportRecommendations: `Consider adding a search bar or icon to make it easier for users to find specific content they are looking for. 2) Clarify the purpose of the "All" tab, or consider renaming it to something more intuitive for first-time users. 3) Provide clear options for users to create playlists, add songs to their library, or save content for later listening. 4) Experiment with reducing the amount of text and increasing the size of album artwork to create a more visually engaging and easily scannable interface. 5) As users scroll through content, ensure that the player controls remain easily accessible to allow for seamless playback control.`,
    },
    {
      name: 'Apple One Landing Page',
      image: appleExample,
      method: 'Increase conversion to signup',
      reportHeadline: 'Conversion Optimization of Apple One Landing Page',
      reportIntro: 'This UX analysis focuses on a single screenshot of the Apple One subscription landing page, with the goal of increasing the conversion rate and getting more visitors to sign up for the service.',
      reportStrenghts: `The headline "The best of Apple. All in one." clearly communicates the value proposition of bundling multiple Apple services into a single subscription. 2) Showcasing popular Apple services like Apple TV+, Apple Music, and Apple Arcade helps visitors quickly understand what's included in the subscription. 3) Presenting three distinct pricing tiers (Individual, Family, Premier) caters to different user needs and budgets. 4)Including a prominent "Try Apple One Free*" call-to-action button encourages visitors to sign up for a trial.`,
      reportWeaknesses: `While the page highlights key services, it doesn't provide detailed information about each one, which could leave some visitors unsure of the full value. 2) The call-to-action button doesn't stand out strongly from other elements on the page, potentially reducing its effectiveness. 3) There are no user reviews, testimonials, or social proof to help build trust and credibility for the Apple One service. 4) The page doesn't include any limited-time offers or urgency-drivers to encourage immediate sign-ups.`,
      reportRecommendations: `Add a comparison table or expandable sections that clearly break down the specific services and features included in each Apple One tier. 2) Redesign the call-to-action button to be more visually prominent, such as using a contrasting color or placing it in a central location.3)Incorporate user reviews, ratings, or testimonials to provide social proof and build trust in the Apple One service.4)Consider adding a limited-time introductory offer or emphasizing the free trial more heavily to drive urgency and encourage sign-ups.5)Provide links to more detailed information about each included Apple service for visitors who want to learn more before subscribing.`,
    },
    {
      name: 'Calm App Onboarding',
      image: calmExample,
      method: 'Increase user activation',
      reportHeadline: 'Onboarding Optimization of Calm Meditation App',
      reportIntro: 'This UX analysis focuses on a 4-screenshot sequence from the Calm meditation app, with the business goal of increasing user activation. The screenshots showcase the apps onboarding flow, starting with a simple breathing prompt, followed by a screen asking users about their goals, an account creation page, a pricing page highlighting a free trial, and finally a limited-time discount offer for a premium subscription.',
      reportStrenghts: `The onboarding flow is concise and focused on quickly guiding users to the app's core value proposition of relaxation and mindfulness. 2) The app presents a clear call-to-action on each screen, making it easy for users to progress through the onboarding process. 3)The pricing page effectively communicates the benefits of the premium subscription while still offering a free trial to lower the barrier to entry. 4) The limited-time discount offer creates a sense of urgency and provides an additional incentive for users to activate.
      `,
      reportWeaknesses: `The account creation screen may introduce friction and potentially deter some users from completing the onboarding process. 2) The pricing information could be more prominently displayed earlier in the flow to set clearer expectations for users. 3) The onboarding flow does not appear to include any personalization based on the user's selected goals, which could make the experience feel less tailored and relevant.`,
      reportRecommendations: `Consider allowing users to explore some of the app's content before requiring account creation, to better showcase the value and build trust. 2) Experiment with introducing pricing information earlier in the flow, perhaps on the goals selection screen, to provide transparency and prime users for the subscription offer. 3) Incorporate personalized content recommendations or features based on the user's chosen goals to create a more engaging and relevant onboarding experience. 4) Test different variations of the limited-time offer, such as the discount amount and duration, to optimize conversion rates.`,
    },
    {
      name: 'Epidemic Sound Checkout',
      image: epidemicExample,
      method: 'Increase conversion to paid',
      reportHeadline: 'Conversion Optimization of Epidemic Sounds Checkout',
      reportIntro: 'This evaluation analyzes a series of 4 screenshots from the Epidemic Sound website, with the business goal of increasing conversion to paid subscriptions. The screenshots showcase the homepage, subscription plans, account creation, and free trial signup flow.',
      reportStrenghts: `The homepage effectively communicates the key value proposition and benefits of the service, such as access to a large music catalog and exclusive soundtracking tools. 2) Subscription plans are clearly presented with pricing, billing frequency, and key features for each tier, helping users compare options. 3) The account creation process is streamlined, with options to sign up via Google, Apple, or email, reducing friction. 4) The free trial signup form is well-designed, with clear pricing information, fields for essential user details, and a prominent CTA button.
      `,
      reportWeaknesses: `The differences between the Personal and Commercial plans may not be immediately clear to all users based on the information provided. 2) Features like "Monetize 1 channel per platform" may be confusing to users. 3) The account creation screen lacks information about the benefits of creating an account, which could deter some users. 4) Requiring an account to be able to upgrade to a plan may introduce unnecessary friction for some users. 5) The free trial signup form does not mention the ability to cancel anytime, which is stated later but could be surfaced earlier to reduce hesitation.
      `,
      reportRecommendations: `Provide more context on the subscription plans page to help users understand which option best fits their needs. 2) Clarify the meaning and implications of more technical features or limits. 3) Include brief messaging on the account creation screen to reinforce the benefits of signing up. 4) Consider moving the account creation step to let users upgrade before they sign up. 5) Surface the "cancel anytime" messaging earlier in the free trial signup flow to build trust and reduce barriers to getting started. 5) Consider adding customer testimonials or social proof throughout the flow to build credibility and encourage conversion.
      `,
    },
    // Add more examples here...
  ];


  
  


const features = [
  {
  name: 'Comprehensive Design Reviews',
  description:
  'Get in-depth feedback on every aspect of your UX by uploading individual screens or complete user flows. Our AI analyzes your designs holistically to provide actionable insights.',
  icon: ArrowUpTrayIcon,
  },
  {
  name: 'Multiple UX Evaluation Methods',
  description:
  'Identify and fix UX issues quickly with a range of powerful evaluation frameworks, including heuristic evaluation, cognitive walkthrough, emotional analysis, and accessibility checks.',
  icon: StarIcon,
  },
  {
  name: 'Lightning-Fast Insights',
  description:
  'Get professional-level feedback in just 60 seconds. Our AI rapidly analyzes your designs and provides detailed strengths, weaknesses, and recommendations for creating exceptional user experiences.',
  icon: DocumentCheckIcon,
  },
  {
  name: 'Streamlined Workflow',
  description:
  'Easily manage and track your design iterations with our full history feature. Revisit past reviews and suggestions to continuously refine and improve your designs.',
  icon: ClockIcon,
  },
  ]

  const benefits = [
    {
    name: 'Landing Pages',
    description:
    'Refine layout, visual hierarchy and CTA placement to effectively communicate your value proposition and drive results.',
    metrics: [
      'Increase signups',
      'Reduce bounce rate',
      'Clarify USPs',
    ],
    icon: NewspaperIcon,
    },
    {
      name: 'Onboarding Flows',
      description: 'Identify points of confusion and get suggestions on how to showcase your key features and benefits.',
      metrics: [
        'Increase user activation',
        'Improve feature adoption',
        'Reduce funnel drop-off',
      ],
      icon: UserPlusIcon,
      },
    {
    name: 'Payment Flows',
    description: 'Identify friction points to optimize your payment flow and ultimately increase revenue.',
    metrics: [
      'Increase conversion rate',
      'Increase average order value',
      'Enhance trust and security',
    ],
    icon: CreditCardIcon,
    },
    ]



const tiers = [
  {
    name: 'Starter',
    id: 'tier-hobby',
    href: '#',
    priceMonthly: '$0',
    description: "Try it out for free and experience the full product with no strings attached.",
    features: ['2 reviews', 'No credit card needed', 'Try before you buy', /*'24-hour support response time'*/],
    featured: false,
  },
  {
    name: 'Premium',
    id: 'tier-enterprise',
    href: '#',
    priceMonthly: '$19',
    description: 'Take your designs, flows and landing pages to the next level and create real results for your business and users.',
    features: [
      'Unlimited reviews',
      'Review screens & flows',
      'Invest in better UX',
      'Cancel anytime',
     /* 'Marketing automations',
      'Custom integrations',*/
    ],
    featured: true,
  },
]

const howitworks = [
  {
    name: 'Upload your design',
    description:
      'Share your design drafts or live product screenshots.',
    icon: CheckCircleIcon,
  },
  {
    name: 'Choose your focus',
    description: 'Select the specific areas or metrics you want to improve.',
    icon: CheckCircleIcon,
  },
  {
    name: 'Receive instant expert insights',
    description: 'Get professional-grade feedback in just 60 seconds, with detailed analysis and actionable recommendations from our AI.',
    icon: CheckCircleIcon,
  },
]


const faqs = [
  {
    id: 1,
    question: "How does designreview.ai work?",
    answer:
      "designreview.ai uses advanced AI algorithms to analyze your designs and provide detailed feedback. Simply upload your screenshots or user flows, and our AI will generate a comprehensive design review within 60 seconds.",
  },
  {
    id: 2,
    question: "What types of designs can I upload for review?",
    answer:
      "You can upload individual screenshots or entire user flows of your web or mobile app designs. Our AI can handle various design formats, including wireframes, mockups, and high-fidelity prototypes.",
  },
  {
    id: 3,
    question: "Can I integrate designreview.ai with my existing design tools?",
    answer:
      "Currently, designreview.ai works independently as a web-based platform. However, we are actively exploring integrations with popular design tools to streamline the review process and provide a seamless experience for designers.",
  },
  {
    id: 4,
    question: "How can I get started with designreview.ai?",
    answer:
      "Getting started with designreview.ai is easy. Simply sign up with Google, check out with Stripe, and you'll be able to upload your designs and receive AI-powered feedback instantly.",
  },
  {
    id: 5,
    question: "Was this page designed with designreview.ai?",
    answer:
      "Of course! By implementing the recommendations from designreview.ai, we have seen a +82% increase in signups. We hope you sign up, too :)",
  },
];


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const stripePromise = loadStripe('pk_live_51P1OQS04pj69RFSAkqtf17RNNT1bXKytGBgLTeIrKHH5lNtWqkASpbbJmBN8GEc83FzYfYdCMeJQAkZxP8aVr3Vt00IOumeExQ');




export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [show, setShow] = useState(false)
 

  const [headline, setHeadline] = useState('');
  const [intro, setIntro] = useState('');
  const [strengths, setStrengths] = useState('');
  const [weaknesses, setWeaknesses] = useState('');
  const [recommendations, setRecommendations] = useState('');
  
  const [currentExampleIndex, setCurrentExampleIndex] = useState(0);
  const currentExample = examples[currentExampleIndex];
  
  const [cleanupFunctions, setCleanupFunctions] = useState<(() => void)[]>([]);
  
  const typeWriter = (text: string, setter: React.Dispatch<React.SetStateAction<string>>, callback?: () => void) => {
    let i = 0;
    const timer = setInterval(() => {
      if (i < text.length) {
        setter(text.slice(0, i + 1));
        i++;
      } else {
        clearInterval(timer);
        if (callback) {
          callback();
        }
      }
    }, 5);
  
    return () => {
      clearInterval(timer);
    };
  };
  
  useEffect(() => {
    resetContent();
  
    const cleanupHeadline = typeWriter(currentExample.reportHeadline, setHeadline, () => {
      const cleanupIntro = typeWriter(currentExample.reportIntro, setIntro, () => {
        const cleanupStrengths = typeWriter(currentExample.reportStrenghts, setStrengths, () => {
          const cleanupWeaknesses = typeWriter(currentExample.reportWeaknesses, setWeaknesses, () => {
            const cleanupRecommendations = typeWriter(currentExample.reportRecommendations, setRecommendations);
            setCleanupFunctions((prevCleanupFunctions) => [...prevCleanupFunctions, cleanupRecommendations]);
          });
          setCleanupFunctions((prevCleanupFunctions) => [...prevCleanupFunctions, cleanupWeaknesses]);
        });
        setCleanupFunctions((prevCleanupFunctions) => [...prevCleanupFunctions, cleanupStrengths]);
      });
      setCleanupFunctions((prevCleanupFunctions) => [...prevCleanupFunctions, cleanupIntro]);
    });
  
    setCleanupFunctions((prevCleanupFunctions) => [...prevCleanupFunctions, cleanupHeadline]);
  
    return () => {
      cleanupFunctions.forEach((cleanup) => cleanup());
    };
  }, [currentExample]);
  
  const resetContent = () => {
    setHeadline('');
    setIntro('');
    setStrengths('');
    setWeaknesses('');
    setRecommendations('');
  };
  
  const handlePreviousExample = () => {
    cleanupFunctions.forEach((cleanup) => cleanup());
    resetContent();
    setCurrentExampleIndex((prevIndex) => (prevIndex === 0 ? examples.length - 1 : prevIndex - 1));
  };
  
  const handleNextExample = () => {
    cleanupFunctions.forEach((cleanup) => cleanup());
    resetContent();
    setCurrentExampleIndex((prevIndex) => (prevIndex === examples.length - 1 ? 0 : prevIndex + 1));
  };

  


  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // User is logged in
        const idToken = await user.getIdToken(true);
        localStorage.setItem('idToken', idToken);
        // Call the handleUserSignUp function
        await handleUserSignUp(user);
      } else {
        // User is logged out
        localStorage.removeItem('idToken');
        setShow(false);
      }
    });
  
    return () => unsubscribe();
  }, []);

  

  const handleGoogleSignIn = async () => {
    analytics.logEvent('landing_page_sign_in_clicked', {
      button_name: 'sign_in',
    });

    setShow(true);
  
    try {
      await auth.signInWithPopup(googleAuthProvider);
      // The onAuthStateChanged listener will handle the rest
    } catch (error) {
      console.error('Error signing in with Google:', error);
      // Handle specific error scenarios and display appropriate error messages to the user
    }
  };

  const handleUserSignUp = async (user: User) => {
    try {
      const idToken = await user.getIdToken(true);
  
      const response = await axios.post(
        'https://us-central1-avery-59f28.cloudfunctions.net/handleUserSignUp',
        { userId: user.uid },
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${idToken}` } }
      );
  
      const { redirectToStripe, stripeCheckoutUrl } = response.data;
  
      console.log('Response received:', response.data);
  
      if (redirectToStripe) {
        // Redirect the user to the Stripe Checkout page
        navigate('/app');
        /*window.location.href = stripeCheckoutUrl;*/
      } else {
        // User has credits or a Stripe customer ID, navigate to the app
        navigate('/app');
      }
    } catch (error) {
      console.error('Error handling user sign-up:', error);
      // Handle specific error scenarios and display appropriate error messages to the user
    }
  };

    const [frequency, setFrequency] = useState(frequencies[0])

 
  return (
    
    <div className="bg-white">
     
      {/* Header */}
     {/* <header className="absolute inset-x-0 top-0 z-50">
        <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">designreview.ai</span>
              <img
                className="h-8 w-auto"
                src={logo}
                alt=""
              />
            </a>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigation.map((item) => (
              <a key={item.name} href={item.href}       target={item.target}
              className="text-sm font-semibold leading-6 text-gray-900">
                {item.name}
              </a>
            ))}
          </div>
         
 
         
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <button onClick={handleGoogleSignIn} className="text-sm font-semibold leading-6 text-gray-900">
Log in <span aria-hidden="true">&rarr;</span>
            </button>    
            </div> 



        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">designreview.ai</span>
                <img
                  className="h-8 w-auto"
                  src={logo}
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      target={item.target}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <button onClick={handleGoogleSignIn}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </button>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
                  </header>*/}

<header className="absolute inset-x-0 top-0 z-50">
        <div className="mx-auto max-w-7xl">
          <div className="px-6 pt-6 lg:max-w-2xl lg:pl-8 lg:pr-0">
            <nav className="flex items-center justify-between lg:justify-start" aria-label="Global">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">designreview.ai</span>
                <img
                  alt="designreview.ai"
                  className="h-8 w-auto"
                  src={logo}
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700 lg:hidden"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="hidden lg:ml-12 lg:flex lg:gap-x-14">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="hidden lg:ml-12 lg:flex lg:gap-x-14">
                  <button onClick={handleGoogleSignIn}
                    className="text-sm font-semibold leading-6 text-gray-900"
                  >
                    Sign in
                  </button>
                </div>
            </nav>
          </div>
        </div>
        <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">designreview.ai</span>
                <img
                  className="h-8 w-auto"
                  src={logo}
                  alt=""
                />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="py-6">
                  <button
                   onSubmit={handleGoogleSignIn}
                    className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    Log in
                  </button>
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>

      <main className="isolate">
        {/* Hero section */}
 <div className="relative">
        <div className="mx-auto max-w-7xl">
          <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
            <svg
              className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-white lg:block"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="0,0 90,0 50,100 0,100" />
            </svg>

            <div className="relative px-6 mt-32 mb-16 sm:py-24 lg:mt-8 lg:px-8 lg:pr-0">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                <div className="hidden sm:mb-10 sm:flex">
                <span className="rounded-full bg-indigo-600/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-600/10">
                Top AI product in 2024
              </span>
                </div>
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
               High-performing designs in minutes, <br />not weeks
                </h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                Make your designs user-friendly and high-converting before they reach your audience with instant, professional design reviews.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                <a 
  href="https://docs.google.com/forms/d/e/1FAIpQLSe5fBJ-4z5-0p2PSiG_IrmB9jwqwa3mqX4bDNriA345us6ZZA/viewform" 
  target="_blank"
>
                <button
  className="flex items-center justify-center w-full md:w-auto px-8 py-3 text-lg font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
>
  <SparklesIcon className="w-6 h-6 mr-2" />
  Sign up for waitlist
</button>
</a>
                </div>



          <div className="flex flex-col md:flex-row justify-center md:justify-start items-center md:items-start gap-6 mt-8">
      <div className="flex -space-x-2">
        <img
          className="inline-block size-[38px] rounded-full ring-2 ring-white"
          src={person1}
          alt="Person 1"
        />
        <img
          className="inline-block size-[38px] rounded-full ring-2 ring-white"
          src={person2}
          alt="Person 2"
        />
        <img
          className="inline-block size-[38px] rounded-full ring-2 ring-white"
          src={person3}
          alt="Person 3"
        />
        <img
          className="inline-block size-[38px] rounded-full ring-2 ring-white"
          src={person4}
          alt="Person 4"
        />
        <img
          className="inline-block size-[38px] rounded-full ring-2 ring-white"
          src={person6}
          alt="Person 5"
        />
      </div>
      <div className="flex flex-col justify-center items-center md:items-start gap-2">
        <div className="flex">
          {[...Array(5)].map((_, index) => (
            <svg
              key={index}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="w-5 h-5 text-yellow-500"
            >
              <path
                fillRule="evenodd"
                d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401z"
                clipRule="evenodd"              />
            </svg>
          ))}
        </div>
        <div className="text-base text-base-content/80">
  <span className="font-semibold text-base-content">3847</span> users work smarter
</div>
      </div>
    </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full"
            src={explanatoryImage}
            alt=""
          />
        </div>
      </div>
    


{/* Logo cloud */}
<div className="bg-white py-32 sm:py-6 mb-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
          Loved by employees from the world's most innovative companies
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img
            className="col-span-2 max-h-10 w-full object-contain lg:col-span-1 filter grayscale"
            src={logo1}
            alt="Google Logo"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-10 w-full object-contain lg:col-span-1 filter grayscale"
            src={logo2}
            alt="Spotify Logo"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-10 w-full object-contain lg:col-span-1 filter grayscale"
            src={logo3}
            alt="Tuple"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-10 w-full object-contain sm:col-start-2 lg:col-span-1 filter grayscale"
            src={logo4}
            alt="SavvyCal"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 col-start-2 max-h-10 w-full object-contain sm:col-start-auto lg:col-span-1 filter grayscale"
            src={logo5}
            alt="Statamic"
            width={158}
            height={48}
          />
        </div>
      </div>
    </div>



        {/* Problem statement */}



        <div className="bg-white py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl lg:text-center">
      <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
  Design <s>takes forever</s> <span className="text-green-600"><br />is easy</span>.
</p>
      <p className="mt-6 text-lg leading-8 text-gray-600">
      Change the way you build apps and websites with designreview.ai.</p>
    </div>
    <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 sm:mt-20 lg:max-w-4xl lg:grid-cols-2">
      <article className="relative isolate flex flex-col justify-center overflow-hidden rounded-2xl bg-red-100 px-8 py-12 ring-4 ring-inset ring-red-500">
        <div className="flex flex-col items-start gap-y-1 text-sm leading-6 text-red-700">
          <h3 className="text-lg font-semibold leading-6">👎 Before</h3>
          <ul className="mt-4 space-y-2 text-base leading-7">
            <li className="flex items-start">
              <XCircleIcon className="h-6 w-6 flex-none text-red-500" aria-hidden="true" />
              <span className="ml-3">Weeks spent on usability testing</span>
            </li>
            <li className="flex items-start">
              <XCircleIcon className="h-6 w-6 flex-none text-red-500" aria-hidden="true" />
              <span className="ml-3">Lengthy discussions</span>
            </li>
            <li className="flex items-start">
              <XCircleIcon className="h-6 w-6 flex-none text-red-500" aria-hidden="true" />
              <span className="ml-3">Opinions over facts</span>
            </li>
            <li className="flex items-start">
              <XCircleIcon className="h-6 w-6 flex-none text-red-500" aria-hidden="true" />
              <span className="ml-3">Not knowing what to improve</span>
            </li>
            <li className="flex items-start">
              <XCircleIcon className="h-6 w-6 flex-none text-red-500" aria-hidden="true" />
              <span className="ml-3">Shipping UX bugs</span>
            </li>
            <li className="flex items-start">
              <XCircleIcon className="h-6 w-6 flex-none text-red-500" aria-hidden="true" />
              <span className="ml-3">Not reaching full potential</span>
            </li>
          </ul>
        </div>
      </article>
      <article className="relative isolate flex flex-col justify-center overflow-hidden rounded-2xl bg-green-100 px-8 py-12 ring-4 ring-inset ring-green-600">
        <div className="flex flex-col items-start gap-y-1 text-sm leading-6 text-green-600">
          <h3 className="text-lg font-semibold leading-6">👍 With designreview.ai</h3>
          <ul className="mt-4 space-y-2 text-base leading-7">
            <li className="flex items-start">
              <CheckCircleIcon className="h-6 w-6 flex-none text-green-600" aria-hidden="true" />
              <span className="ml-3">Instant feedback</span>
            </li>
            <li className="flex items-start">
              <CheckCircleIcon className="h-6 w-6 flex-none text-green-600" aria-hidden="true" />
              <span className="ml-3">Following best practices</span>
            </li>
            <li className="flex items-start">
              <CheckCircleIcon className="h-6 w-6 flex-none text-green-600" aria-hidden="true" />
              <span className="ml-3">Increased confidence in decisions</span>
            </li>
            <li className="flex items-start">
              <CheckCircleIcon className="h-6 w-6 flex-none text-green-600" aria-hidden="true" />
              <span className="ml-3">Faster iterations</span>
            </li>
            <li className="flex items-start">
              <CheckCircleIcon className="h-6 w-6 flex-none text-green-600" aria-hidden="true" />
              <span className="ml-3">Improved user experience</span>
            </li>
            <li className="flex items-start">
              <CheckCircleIcon className="h-6 w-6 flex-none text-green-600" aria-hidden="true" />
              <span className="ml-3">Increased growth</span>
            </li>
          </ul>
        </div>
      </article>
    </div>
  </div>
</div>





{/*How it works*/}

<div className="overflow-hidden bg-white py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
      <div className="lg:pr-8 lg:pt-4">
        <div className="lg:max-w-lg">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Design feedback in 3 simple steps</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">How it works</p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          Improving your designs has never been easier.
          </p>
          <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
            {howitworks.map((feature, index) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="inline font-semibold text-gray-900">
                  <span className="absolute left-0 top-0 flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 text-white">                    {index + 1}
                  </span>
                  {feature.name}
                </dt>{' '}
                    <br /><dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={mainScreen}
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>






   {/* See it in action */}


   <div className="bg-white py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div id="examples" className="mx-auto max-w-2xl lg:text-center">
      <h2 className="text-base font-semibold leading-7 text-indigo-600">Real-world examples</h2>
      <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
      See real results in action
      </p>
      <p className="mt-6 text-lg leading-8 text-gray-600">
      Explore examples of how designreview.ai works. These are authentic reviews, not just marketing material, showcasing the true power of designreview.ai.
      </p>
    </div>



  

    <div className="mt-16 sm:mt-20 lg:mt-24">
    <div id="examples" className="mx-auto max-w-2xl lg:text-center">
  <div className="mb-12 flex items-center justify-center">
    <button
      type="button"
      className="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
      onClick={handlePreviousExample}
    >
      <span className="sr-only">Previous</span>
      <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
    </button>

    <div className="mx-4 w-80 text-center">
      <h3 className="text-xl font-semibold text-gray-900">{currentExample.name}</h3>
      <p className="mt-1 text-base text-gray-600">{currentExample.method}</p>
    </div>

    <button
      type="button"
      className="relative inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
      onClick={handleNextExample}
    >
      <span className="sr-only">Next</span>
      <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
    </button>
  </div>
</div>

  <div className="flex flex-col md:flex-row">
  <div className="md:w-1/3 mb-4 md:mb-0 md:pr-8">



  <div className="mt-4">
  <img
    src={currentExample.image}
    alt={currentExample.name}
    className="rounded-lg object-cover w-full"
    style={{ height: 'auto' }}
  />
</div>
    </div>
    <div className="md:w-2/3 md:pl-8">
  <div className="rounded-lg border border-gray-200 bg-white p-6 shadow-md min-h-[600px]">
    <div className="px-2 py-4 md:px-6">
    <h3 className="text-xl font-semibold text-gray-900">{headline}</h3>
      <p className="mt-1 text-base text-gray-600 mb-6">{intro}</p>
      {strengths && (
        <div className="mb-4">
          <div className="flex items-center mb-1">
            <div className="w-2 h-2 bg-green-500 rounded-full mr-2"></div>
            <h5 className="text-base font-semibold">Strengths</h5>
          </div>
          <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
            {strengths.split(/\d+\)\s?/).filter(Boolean).map((line, index) => (
              <li key={index} style={{ position: 'relative' }}>
                <span style={{ position: 'absolute', left: '-20px', top: '0' }}>•</span>
                {line.trim()}
              </li>
            ))}
          </ul>
        </div>
      )}
      {weaknesses && (
        <div className="mb-4">
          <div className="flex items-center mb-1">
            <div className="w-2 h-2 bg-red-500 rounded-full mr-2"></div>
            <h5 className="text-base font-semibold">Weaknesses</h5>
          </div>
          <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
            {weaknesses.split(/\d+\)\s?/).filter(Boolean).map((line, index) => (
              <li key={index} style={{ position: 'relative' }}>
                <span style={{ position: 'absolute', left: '-20px', top: '0' }}>•</span>
                {line.trim()}
              </li>
            ))}
          </ul>
        </div>
      )}
      {recommendations && (
        <div>
          <div className="flex items-center mb-1">
            <div className="w-2 h-2 bg-indigo-500 rounded-full mr-2"></div>
            <h5 className="text-base font-semibold">Recommendations</h5>
          </div>
          <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
            {recommendations.split(/\d+\)\s?/).filter(Boolean).map((line, index) => (
              <li key={index} style={{ position: 'relative' }}>
                <span style={{ position: 'absolute', left: '-20px', top: '0' }}>•</span>
                {line.trim()}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  </div>
</div>
  </div>
    </div>
  </div>
</div>




{/*What it can be used for*/}


<div className="bg-white py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl lg:text-center">
      <h2 className="text-base font-semibold leading-7 text-indigo-600">Achieve your goals</h2>
      <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        Unlock the full potential of your product
      </p>
      <p className="mt-6 text-lg leading-8 text-gray-600">
        designreview.ai helps you optimize your designs to achieve your goals. 
      </p>
    </div>
    <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
      <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
        {benefits.map((feature) => (
          <div key={feature.name} className="flex flex-col">
            <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
              <feature.icon className="h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
              {feature.name}
            </dt>
            <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
              <p className="flex-auto">{feature.description}</p>
              <ul className="mt-4 space-y-2">
                {feature.metrics.map((metric) => (
                  <li key={metric} className="flex items-start">
                    <CheckIcon className="h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                    <span className="ml-2">{metric}</span>
                  </li>
                ))}
              </ul>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  </div>
</div>



        {/* Testimonial section */}


        <section className="bg-white px-6 py-24 sm:py-18 lg:px-8">
      <figure className="mx-auto max-w-2xl">
        <p className="sr-only">5 out of 5 stars</p>
        <div className="flex gap-x-1 text-yellow-500">
          <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
          <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
          <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
          <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
          <StarIcon className="h-5 w-5 flex-none" aria-hidden="true" />
        </div>
        <blockquote className="mt-10 text-xl font-semibold leading-8 tracking-tight text-gray-900 sm:text-2xl sm:leading-9">
          <p>
            “Since we started following designreview.ai's recommendations, our signups have increased by 52%. The best part is that we achieved this without spending time on lengthy discussions about what changes to make or running usability tests on our initial designs.”
          </p>
        </blockquote>
        <figcaption className="mt-10 flex items-center gap-x-6">
          <img
            className="h-12 w-12 rounded-full bg-gray-50"
            src={person5}
            alt=""
          />
          <div className="text-sm leading-6">
            <div className="font-semibold text-gray-900">Fiona</div>
            <div className="mt-0.5 text-gray-600">Founder, myPurpleDay</div>
          </div>
        </figcaption>
      </figure>
    </section>



        {/* Pricing section */}

        <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div id="pricing" className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Pricing</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          Boost your business with better UX</p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
        Whether you're a freelancer or a large enterprise, designreview.ai has a plan that fits your needs and helps you create designs that make an impact.        </p>
       {/* <div className="mt-16 flex justify-center">
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-1 rounded-full p-1 text-center text-xs font-semibold leading-5 ring-1 ring-inset ring-gray-200"
          >
            <RadioGroup.Label className="sr-only">Payment frequency</RadioGroup.Label>
            {frequencies.map((option) => (
              <RadioGroup.Option
                key={option.value}
                value={option}
                className={({ checked }) =>
                  classNames(
                    checked ? 'bg-indigo-600 text-white' : 'text-gray-500',
                    'cursor-pointer rounded-full px-2.5 py-1'
                  )
                }
              >
                <span>{option.label}</span>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div> */}

        <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {paidTiers.map((tier) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'ring-2 ring-indigo-600' : 'ring-1 ring-gray-200',
                'rounded-3xl p-8 xl:p-10'
              )}
            >
              <div className="flex items-center justify-between gap-x-4">
                <h3
                  id={tier.id}
                  className={classNames(
                    tier.mostPopular ? 'text-indigo-600' : 'text-gray-900',
                    'text-lg font-semibold leading-8'
                  )}
                >
                  {tier.name}
                </h3>
                {tier.mostPopular ? (
                  <p className="rounded-full bg-indigo-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-indigo-600">
                    Get it now
                  </p>
                ) : null}
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-600">{tier.description}</p>
              <p className="mt-6 flex items-baseline gap-x-1">
                <span className="text-4xl font-bold tracking-tight text-gray-900">  {tier.price[frequency.value as keyof typeof tier.price]}</span>
                <span className="text-sm font-semibold leading-6 text-gray-600">{frequency.priceSuffix}</span>
              </p>
              <button
  onClick={handleGoogleSignIn}
  aria-describedby={tier.id}
  className={classNames(
    tier.mostPopular
      ? 'bg-indigo-600 text-white shadow-sm hover:bg-indigo-500'
      : 'text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
    'mt-6 w-full rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
  )}
>
  {tier.cta}
</button>
              <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
       


        {/* FAQs */}
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
        {/* CTA section */}
        <div className="relative -z-10 mt-32 px-6 lg:px-8">
          <div
            className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 transform-gpu justify-center overflow-hidden blur-3xl sm:bottom-0 sm:right-[calc(50%-6rem)] sm:top-auto sm:translate-y-0 sm:transform-gpu sm:justify-end"
            aria-hidden="true"
          >
            <div
              className="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-25"
              style={{
                clipPath:
                  'polygon(73.6% 48.6%, 91.7% 88.5%, 100% 53.9%, 97.4% 18.1%, 92.5% 15.4%, 75.7% 36.3%, 55.3% 52.8%, 46.5% 50.9%, 45% 37.4%, 50.3% 13.1%, 21.3% 36.2%, 0.1% 0.1%, 5.4% 49.1%, 21.4% 36.4%, 58.9% 100%, 73.6% 48.6%)',
              }}
            />
          </div>
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Start creating designs that drive results
            </h2>
            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
            Get started with designreview.ai now and unlock the key to high-performing, user-centric designs.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              
            <a 
  href="https://docs.google.com/forms/d/e/1FAIpQLSe5fBJ-4z5-0p2PSiG_IrmB9jwqwa3mqX4bDNriA345us6ZZA/viewform" 
  target="_blank"
>
                <button
  className="flex items-center justify-center w-full md:w-auto px-8 py-3 text-lg font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
>
  <SparklesIcon className="w-6 h-6 mr-2" />
  Sign up for waitlist
</button>
</a>
         
            </div>
          </div>
          <div
            className="absolute left-1/2 right-0 top-full -z-10 hidden -translate-y-1/2 transform-gpu overflow-hidden blur-3xl sm:block"
            aria-hidden="true"
          >
            <div
              className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>

          
          
        </div>

        <div className="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8">
  <nav className="flex flex-wrap justify-center -mb-6" aria-label="Footer">
    <div className="pb-6 px-4">
      <a
        href="/terms"
        className="text-sm leading-6 text-gray-600 hover:text-gray-900"
      >
        Terms of Use
      </a>
    </div>
    <div className="pb-6 px-4">
      <a
        href="/privacy"
        className="text-sm leading-6 text-gray-600 hover:text-gray-900"
      >
        Privacy Policy
      </a>
    </div>
    <div className="pb-6 px-4">
      <a
        href="https://peachyinnovation.com"
        target="_blank"
        rel="noopener noreferrer"
        className="text-sm leading-6 text-gray-600 hover:text-gray-900"
      >
        Contact Us
      </a>
    </div>
  </nav>
  <p className="mt-10 text-center text-xs leading-5 text-gray-500">
    &copy; 2024, Peachy Innovation AB. All rights reserved.
  </p>
</div>

        <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setShow}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Signing in...
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Hang on a moment while we're signing you in. 
                      </p>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

        
      </main>

      {/* Footer */}

      <footer className="bg-white">

</footer>

</div>

    
  )
}




{/* old hero */}




{/*<div className="relative pt-14">
<div
  className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
  aria-hidden="true"
>
  <div
    className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
    style={{
      clipPath:
        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
    }}
  />
</div>
<div className="py-24 sm:py-32">
  <div className="mx-auto max-w-7xl px-6 lg:px-8">
    <div className="mx-auto max-w-2xl text-center">
      <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
      Unlock exceptional UX with AI-powered feedback</h1>
      <p className="mt-6 text-lg leading-8 text-gray-600">
      Get professional design reviews in just 60 seconds, ensuring your designs are user-friendly and high-converting before they reach your audience.                </p>
      <div className="mt-10 flex items-center justify-center gap-x-6">
     
     
      <button onClick={handleGoogleSignIn}

          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
Try it for free
        </button>




        <a href="#features" className="text-sm font-semibold leading-6 text-gray-900">
          Learn more <span aria-hidden="true">→</span>
        </a>
      </div>
    </div>
    <div className="mt-16 flow-root sm:mt-24">
      <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
        <img
          src={mainScreen}
          alt="App screenshot"
          width={2432}
          height={1442}
          className="rounded-md shadow-2xl ring-1 ring-gray-900/10"
        />
      </div>
    </div>
  </div>
</div>
<div
  className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
  aria-hidden="true"
>
  <div
    className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
    style={{
      clipPath:
        'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
    }}
  />
</div>
</div> */}