import { useState } from 'react'
import { Link } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/20/solid'
import { functions, auth, analytics } from '../firebase';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios'; 


const includedFeatures = [
    'Unlimited reviews',
    'Cutting-edge AI',
    'Priority support',
    'Cancel anytime',
  ]


  const stripePromise = loadStripe('pk_live_51P1OQS04pj69RFSAkqtf17RNNT1bXKytGBgLTeIrKHH5lNtWqkASpbbJmBN8GEc83FzYfYdCMeJQAkZxP8aVr3Vt00IOumeExQ');


  const PaywallPage = () => {
    const handleSubscriptionPurchase = async () => {
      analytics.logEvent('upgrade_page_upgrade', {
        button_name: 'upgrade_button',
      });
      const user = auth.currentUser;
      if (!user) {
        console.error('User not authenticated');
        // TODO: Handle user not authenticated error
        return;
      }
  
      const userId = user.uid;
      console.log('User Id:', userId);
  
      try {
        const idToken = await user.getIdToken(true);
        const response = await axios.post(
          'https://us-central1-avery-59f28.cloudfunctions.net/createCheckoutSession',
          { userId },
          { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${idToken}` } }
        );
  
        const data = response.data;
        if (data.sessionUrl) {
          window.location.href = data.sessionUrl;
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (error) {
        console.error('Error creating Checkout Session:', error);
      }
    };
  
    return (
        <div className="bg-white py-6 sm:py-8">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
           {/* <div className="mx-auto max-w-2xl sm:text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Simple no-tricks pricing</h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et quasi iusto modi velit ut non voluptas
                in. Explicabo id ut laborum.
              </p>
            </div>  */}
            <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
              <div className="p-8 sm:p-10 lg:flex-auto">
                <h3 className="text-2xl font-bold tracking-tight text-gray-900">Premium membership</h3>
                <p className="mt-6 text-base leading-7 text-gray-600">
                Take your designs, flows and landing pages to the next level and create real results for your business and users.
                </p>
                <div className="mt-10 flex items-center gap-x-4">
                  <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
                  <div className="h-px flex-auto bg-gray-100" />
                </div>
                <ul
                  role="list"
                  className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
                >
                  {includedFeatures.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                  <div className="mx-auto max-w-xs px-8">
                    <p className="text-base font-semibold text-gray-600">Monthly subscription</p>
                    <p className="mt-6 flex items-baseline justify-center gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">$19</span>
                      <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">USD</span>
                    </p>
                    <a href="#"
  onClick={handleSubscriptionPurchase}
  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Upgrade now
      </a>
                    <p className="mt-6 text-xs leading-5 text-gray-600">
                      Payments, invoices and receipts conveniently and securely managed by Stripe.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
};


export default PaywallPage;