import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { auth } from '../firebase';

type DesignReview = {
  channelId: string;
  reviewName: string;
  lastResponseTimestamp: string | null;
  overallPros: string;
  overallCons: string;
  overallRecommendations: string;
  screenshotDetails: ScreenshotDetails[];
  screenshots: string[];
  pros: string;
  cons: string;
  recommendations: string;
};

type ScreenshotDetails = {
  pros: string | string[];
  cons: string | string[];
  recommendations: string | string[];
};

type HistoryReviewPageProps = {
  channelId: string;
};

const HistoryReviewPage: React.FC<HistoryReviewPageProps> = ({ channelId }) => {
  const [selectedReview, setSelectedReview] = useState<DesignReview | null>(null);

  useEffect(() => {
    const fetchReview = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error('User not authenticated');
          return;
        }
        const idToken = await user.getIdToken(true);
        const response = await axios.post(
          'https://us-central1-avery-59f28.cloudfunctions.net/getDesignReviewbyChannelId',
          { channelId: channelId },
          { headers: { Authorization: `Bearer ${idToken}` } }
        );
        setSelectedReview(response.data);
      } catch (error) {
        console.error('Error fetching design review:', error);
      }
    };

    fetchReview();
  }, [channelId]);

  if (!selectedReview) {
    return <div>Loading review...</div>;
  }

  return (
    <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
      <div className="mt-8">
        <ul role="list" className="space-y-3">
          {selectedReview.overallPros && (
            <li className="overflow-hidden rounded-md bg-white px-6 py-4 shadow">
              <div className="mb-4">
                <div className="flex items-center mb-1">
                  <div className="w-2 h-2 bg-green-500 rounded-full mr-2"></div>
                  <h5 className="text-base font-semibold">Strengths</h5>
                </div>
                <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                  {selectedReview.overallPros.split(/\d+\)\s?/).filter(Boolean).map((line: string, index: number) => (
                    <li key={index} style={{ position: 'relative' }}>
                      <span style={{ position: 'absolute', left: '-20px', top: '0' }}>•</span>
                      {line.trim()}
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          )}
          {selectedReview.overallCons && (
            <li className="overflow-hidden rounded-md bg-white px-6 py-4 shadow">
              <div className="mb-4">
                <div className="flex items-center mb-1">
                  <div className="w-2 h-2 bg-red-500 rounded-full mr-2"></div>
                  <h5 className="text-base font-semibold">Weaknesses</h5>
                </div>
                <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                  {selectedReview.overallCons.split(/\d+\)\s?/).filter(Boolean).map((line: string, index: number) => (
                    <li key={index} style={{ position: 'relative' }}>
                      <span style={{ position: 'absolute', left: '-20px', top: '0' }}>•</span>
                      {line.trim()}
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          )}
          {selectedReview.overallRecommendations && (
            <li className="overflow-hidden rounded-md bg-white px-6 py-4 shadow">
              <div className="mb-4">
                <div className="flex items-center mb-1">
                  <div className="w-2 h-2 bg-indigo-500 rounded-full mr-2"></div>
                  <h5 className="text-base font-semibold">Recommendations</h5>
                </div>
                <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                  {selectedReview.overallRecommendations.split(/\d+\)\s?/).filter(Boolean).map((line: string, index: number) => (
                    <li key={index} style={{ position: 'relative' }}>
                      <span style={{ position: 'absolute', left: '-20px', top: '0' }}>•</span>
                      {line.trim()}
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          )}
        </ul>

        {selectedReview.screenshotDetails && selectedReview.screenshotDetails.length > 0 ? (
          <div className="mt-8">
            <h4 className="text-lg font-semibold mb-2">Screenshot Details</h4>
            <ul role="list" className="space-y-3">
              {selectedReview.screenshotDetails.map((details: ScreenshotDetails, index: number) => (
                <li key={index} className="overflow-hidden rounded-md bg-white shadow">
                  <div className="flex">
                    <div className="w-1/3">
                      <img src={selectedReview.screenshots[index]} alt={`Screenshot ${index + 1}`} className="h-full w-full object-cover rounded-l-md" />
                    </div>
                    <div className="w-2/3 px-6 py-4">
                      <h5 className="text-base font-semibold mb-2">Screenshot {index + 1}</h5>
                      {(details.pros as string | string[]) && (
                        <div className="mb-2">
                          <h6 className="text-sm font-semibold mb-1">Pros</h6>
                          {typeof details.pros === 'string' ? (
                            <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                              {(details.pros as string).split(/\d+\)\s?/).filter(Boolean).map((pro: string, proIndex: number) => (
                                <li key={proIndex} style={{ position: 'relative' }}>
                                  <span style={{ position: 'absolute', left: '-20px', top: '0' }}>•</span>
                                  {pro.trim()}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                              {(details.pros as string[]).map((pro: string, proIndex: number) => (
                                <li key={proIndex} style={{ position: 'relative' }}>
                                  <span style={{ position: 'absolute', left: '-20px', top: '0' }}>•</span>
                                  {pro.trim().replace(/^\d+\)\s?/, '')}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      )}
                      {(details.cons as string | string[]) && (
                        <div className="mb-2">
                          <h6 className="text-sm font-semibold mb-1">Cons</h6>
                          {typeof details.cons === 'string' ? (
                            <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                              {(details.cons as string).split(/\d+\)\s?/).filter(Boolean).map((con: string, conIndex: number) => (
                                <li key={conIndex} style={{ position: 'relative' }}>
                                  <span style={{ position: 'absolute', left: '-20px', top: '0' }}>•</span>
                                  {con.trim()}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                              {(details.cons as string[]).map((con: string, conIndex: number) => (
                                <li key={conIndex} style={{ position: 'relative' }}>
                                  <span style={{ position: 'absolute', left: '-20px', top: '0' }}>•</span>
                                  {con.trim()}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      )}
                      {(details.recommendations as string | string[]) && (
                        <div className="mb-2">
                          <h6 className="text-sm font-semibold mb-1">Recommendations</h6>
                          {typeof details.recommendations === 'string' ? (
                            <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                              {(details.recommendations as string).split(/\d+\)\s?/).filter(Boolean).map((recommendation: string, recommendationIndex: number) => (
                                <li key={recommendationIndex} style={{ position: 'relative' }}>
                                  <span style={{ position: 'absolute', left: '-20px', top: '0' }}>•</span>
                                  {recommendation.trim()}
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                              {(details.recommendations as string[]).map((recommendation: string, recommendationIndex: number) => (
                                <li key={recommendationIndex} style={{ position: 'relative' }}>
                                  <span style={{ position: 'absolute', left: '-20px', top: '0' }}>•</span>
                                  {recommendation.trim().replace(/^\d+\)\s?/, '')}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            <ul role="list" className="space-y-3">
              {selectedReview.pros && (
                <li className="overflow-hidden rounded-md bg-white px-6 py-4 shadow">
                  <div className="mb-4">
                    <div className="flex items-center mb-1">
                      <div className="w-2 h-2 bg-green-500 rounded-full mr-2"></div>
                      <h5 className="text-base font-semibold">Strengths</h5>
                    </div>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                      {selectedReview.pros.split(/\d+\)\s?/).filter(Boolean).map((line: string, index: number) => (
                        <li key={index} style={{ position: 'relative' }}>
                          <span style={{ position: 'absolute', left: '-20px', top: '0' }}>•</span>
                          {line.trim()}
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              )}
              {selectedReview.cons && (
                <li className="overflow-hidden rounded-md bg-white px-6 py-4 shadow">
                  <div className="mb-4">
                    <div className="flex items-center mb-1">
                      <div className="w-2 h-2 bg-red-500 rounded-full mr-2"></div>
                      <h5 className="text-base font-semibold">Weaknesses</h5>
                    </div>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                      {selectedReview.cons.split(/\d+\)\s?/).filter(Boolean).map((line: string, index: number) => (
                        <li key={index} style={{ position: 'relative' }}>
                          <span style={{ position: 'absolute', left: '-20px', top: '0' }}>•</span>
                          {line.trim()}
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              )}
              {selectedReview.recommendations && (
                <li className="overflow-hidden rounded-md bg-white px-6 py-4 shadow">
                  <div className="mb-4">
                    <div className="flex items-center mb-1">
                      <div className="w-2 h-2 bg-indigo-500 rounded-full mr-2"></div>
                      <h5 className="text-base font-semibold">Recommendations</h5>
                    </div>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
                      {selectedReview.recommendations.split(/\d+\)\s?/).filter(Boolean).map((line: string, index: number) => (
                        <li key={index} style={{ position: 'relative' }}>
                          <span style={{ position: 'absolute', left: '-20px', top: '0' }}>•</span>
                          {line.trim()}
                        </li>
                      ))}
                    </ul>
                  </div>
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoryReviewPage;
