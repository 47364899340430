
import React, { useState, useEffect } from 'react';
import { auth, analytics } from '../firebase';
import { PhotoIcon, CheckCircleIcon } from '@heroicons/react/24/solid';
import { RadioGroup } from '@headlessui/react'
import UpgradePopUp from './UpgradePopUp';
import axios from 'axios';
import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { XCircleIcon } from '@heroicons/react/20/solid'
import { DesignReview } from './ResultPage';
import { SparklesIcon } from '@heroicons/react/20/solid';





{/*Methods*/}
const methodLists = [
    { id: 1, title: 'Increase conversion rate', description: 'Optimize your landing page design and copy to persuade visitors to take the desired action, such as signing up, making a purchase, or requesting a demo.', users: '621 users' },
    { id: 2, title: 'Communicate unique value proposition', description: 'Clearly articulate your unique selling points, benefits, and differentiators to capture visitors attention and motivate them to engage with your offering.', users: '1200 users' },
    { id: 3, title: 'Reduce bounce rates', description: 'Implement design techniques and compelling content to keep visitors engaged, reducing bounce rates and increasing the likelihood of conversion.', users: '2740 users' },
  
  
        {
        id: 4,
        title: 'Increase user activation',
        description: 'Optimize your onboarding flow to guide users towards key activation points, such as completing registration, setting up profiles, or taking critical first steps within your product.',
        },
        {
        id: 5,
        title: 'Improve feature adoption',
        description: 'Implement guided tours, tooltips, and contextual help to highlight core features and functionalities, encouraging users to explore and utilize the full potential of your product.',
        },
        {
        id: 6,
        title: 'Reduce user drop-off',
        description: 'Streamline your onboarding process by minimizing friction points, providing clear instructions, and progressively disclosing information to reduce user confusion and abandonment.',
        },
        { id: 7, title: 'Increase conversion rate', description: 'Optimize your payment flow to minimize friction and abandonment, leading to higher conversion rates and increased revenue.', users: '621 users' },
        { id: 8, title: 'Increase customer value', description: 'Implement strategies to increase average order value (AOV), customer lifetime value (LTV), and encourage repeat purchases through a seamless payment experience.', users: '1200 users' },
        { id: 9, title: 'Enhance trust and security', description: 'Build customer confidence by prominently displaying trust badges, security certifications, and clear privacy policies throughout the payment process.', users: '2740 users' },
          

        ]    
  
  
  interface MethodMapping {
    [key: string]: string;
  }

  const methodMapping: MethodMapping = {
    '1': 'method_b1',
    '2': 'method_b2',
    '3': 'method_b3',
    '4': 'method_c1',
    '5': 'method_c2',
    '6': 'method_c3',
    '7': 'method_d1',
    '8': 'method_d2',
    '9': 'method_d3',
  };

  function classNames(...classes: (string | boolean)[]) {
    return classes.filter(Boolean).join(' ');
  }
  const GoalPage: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState<FileList | null>(null);
    const [uploadedImages, setUploadedImages] = useState<string[]>([]);
    const [userComment, setuserComment] = useState('');
    const [userCredits, setUserCredits] = useState<number | null>(null);
    const [showUpgradePopUp, setShowUpgradePopUp] = useState(false);
    const [showTooBigFile, setShowTooBigFile] = useState(false);
    const [showUploadFailed, setShowUploadFailed] = useState(false);
    const [showAuthError, setShowAuthError] = useState(false);
    const [selectedMethodLists, setSelectedMethodLists] = useState(methodLists[0]);
    const [showAllOptions, setShowAllOptions] = useState(false);
    const visibleOptions = showAllOptions
        ? methodLists
        : methodLists.slice(0, window.innerWidth <= 640 ? 1 : 3);
    const [streamingResult, setStreamingResult] = useState<string>('');
    const [userId, setUserId] = useState<string>('');
    const [channelId, setChannelId] = useState<string>('');




  
       

    {/* Handle uploading images */}
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      analytics.logEvent('upload_page_addedImage', {
        button_name: 'upload_image',
      });
      const selectedFiles = event.target.files;
      if (selectedFiles) {
        const newImages = Array.from(selectedFiles)
          .map((file) => {
            if (file.size > 10 * 1024 * 1024) {
              setShowTooBigFile(true);
              return null;
            }
            return URL.createObjectURL(file);
          })
          .filter((image): image is string => image !== null);
    
        if (uploadedImages.length + newImages.length <= 5) {
          setUploadedImages((prevImages) => {
            const updatedImages = [...prevImages, ...newImages];
            return updatedImages;
          });
          setFiles(selectedFiles);
        }
      }
    };
  
    const handleRemoveImage = (index: number) => {
      analytics.logEvent('upload_page_removeImage', {
        button_name: 'remove_image',
      });
      setUploadedImages((prevImages) => {
        const updatedImages = [...prevImages];
        updatedImages.splice(index, 1);
        return updatedImages;
      });  
      if (files) {
        const updatedFiles = new DataTransfer();
        Array.from(files)
          .filter((_, i) => i !== index)
          .forEach((file) => {
            updatedFiles.items.add(file);
          });
        setFiles(updatedFiles.files);
      }
    };


    {/* Check if user is entitled to review */}

    const fetchUserCredits = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.error('User not authenticated');
        return null;
      }
      try {
        const idToken = await user.getIdToken(true);
        const userId = user.uid;
        console.log('Sending request to getUserCredits with userId:', userId);
        const response = await axios.post(
          'https://us-central1-avery-59f28.cloudfunctions.net/getUserCredits',
          { userId },
          { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${idToken}` } }
        );
        const credits = response.data.credits;
        console.log('User credits:', credits);
        setUserCredits(credits); // Update the state with the retrieved credits
        return credits; // Return the credits value
      } catch (error) {
        if (error instanceof Error) {
          console.error('Error retrieving user credits:', error.message);
        } else {
          console.error('An unknown error occurred:', error);
        }
        // You can choose to set a default value or handle the error state differently
        setUserCredits(0); // Set a default value of 0 credits in case of an error
        return 0; // Return 0 credits in case of an error
      }
    };

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      setIsLoading(true);
      setStreamingResult('');
    
      const user = auth.currentUser;
      if (!user) {
        console.error('User not authenticated');
        setShowAuthError(true);
        setIsLoading(false);
        return;
      }

      const userCredits = await fetchUserCredits();
      if (userCredits === null || userCredits <= 0) {
        setShowUpgradePopUp(true);
        setIsLoading(false);
        return;
      }
  
    
      try {
        const idToken = await user.getIdToken(true);
        const userId = user.uid;
        const methodId = methodMapping[selectedMethodLists.id.toString()];
    
        console.log('Converting uploaded images to base64...');
        const screenshotPromises = uploadedImages.map((image) => {
          return new Promise<string>((resolve, reject) => {
            fetch(image)
              .then((response) => response.blob())
              .then((blob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  resolve(reader.result as string);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })
              .catch(reject);
          });
        });
    
        const screenshots = await Promise.all(screenshotPromises);
    
        const requestData = {
          userId,
          methodId,
          screenshots,
          userComment,
        };
    
        console.log('Request Data:', requestData);
        console.log('Sending request to uploadScreenshot...');
    
        const response = await fetch('https://us-central1-avery-59f28.cloudfunctions.net/uploadScreenshot', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });
    
        if (response.ok) {
          const data = await response.json();
          const channelId = data.channelId;
    
          console.log('Upload successful, establishing SSE connection...');
          console.log('ChannelId is:', channelId)
          
          // Establish SSE connection for streaming design review results
          const eventSource = new EventSource(`https://us-central1-avery-59f28.cloudfunctions.net/sseEndpoint?userId=${userId}&channelId=${channelId}`);
    
          let streamedData = '';
    
          eventSource.onopen = function(event) {
            console.log('SSE connection opened:', event);
          };
    
          eventSource.onmessage = function(event) {
            console.log('Received event:', event);
            const data = JSON.parse(event.data);
    
            // Handle the streaming data here
            streamedData += data.data; // Accumulate the streamed data
    
            if (data.type === 'message_stop') {
              console.log('Streaming complete');
              setStreamingResult(streamedData); // Set the streamingResult state with the complete streamed data
              eventSource.close();
            }
          };
    
          eventSource.onerror = function(event) {
            console.error('SSE error occurred:', event);
    
            if (eventSource.readyState === EventSource.CLOSED) {
              console.error('SSE connection was closed');
            } else if (eventSource.readyState === EventSource.CONNECTING) {
              console.error('SSE connection is reconnecting');
            } else {
              console.error('Unknown SSE error state');
            }
    
            eventSource.close();
          };
    
        } else {
          const errorText = await response.text();
          console.error('Error uploading screenshots:', response.status, errorText);
          setShowUploadFailed(true); // Show upload failed message
        }
      } catch (error) {
        console.error('Error:', error);
        setShowUploadFailed(true); // Show upload failed message
      } finally {
        setIsLoading(false);
      }
    };
    
    useEffect(() => {
      if (userId && channelId) {
        const eventSource = new EventSource(`/sseEndpoint?userId=${userId}&channelId=${channelId}`);
        
        eventSource.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (data.type === 'message_delta') {
            // Handle message delta
            console.log(data);
          } else if (data.type === 'message_stop') {
            eventSource.close();
          }
        };
    
        eventSource.onerror = (error) => {
          console.error('EventSource error:', error);
          eventSource.close();
        };
    
        return () => {
          eventSource.close();
        };
      }
    }, [userId, channelId]);
    
    
    

    {/* streaming works but not outputing

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      setIsLoading(true);
      setStreamingResult('');
    
      const user = auth.currentUser;
      if (!user) {
        console.error('User not authenticated');
        setShowAuthError(true);
        setIsLoading(false);
        return;
      }
    
      try {
        const idToken = await user.getIdToken(true);
        const userId = user.uid;
        const methodId = methodMapping[selectedMethodLists.id.toString()];
        console.log('Converting uploaded images to base64...');
        const screenshotPromises = uploadedImages.map((image) => {
          return new Promise<string>((resolve, reject) => {
            fetch(image)
              .then((response) => response.blob())
              .then((blob) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                  resolve(reader.result as string);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
              })
              .catch(reject);
          });
        });
    
        const screenshots = await Promise.all(screenshotPromises);
    
        const requestData = {
          userId,
          methodId,
          screenshots,
          userComment,
        };
    
        console.log('Request Data:', requestData);
        setIsLoading(true);
        console.log('Sending request to uploadScreenshot...');
    
        const response = await fetch('https://us-central1-avery-59f28.cloudfunctions.net/uploadScreenshot', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });
    
        if (response.ok) {
          const data = await response.json();
          const channelId = data.channelId;
    
          // Establish SSE connection for streaming design review results
          const eventSource = new EventSource(`https://us-central1-avery-59f28.cloudfunctions.net/sseEndpoint?userId=${userId}&channelId=${channelId}`);
    
          let streamedData = '';
    
          const handleMessage = (event: MessageEvent) => {
            const data = JSON.parse(event.data);
            console.log('Received SSE event:', data);
            switch (data.type) {
              case 'message_start':
                // Handle message start event
                break;
              case 'content_block_start':
                // Handle content block start event
                break;
              case 'content_block_delta':
                // Handle content block delta event
                streamedData += data.data;
                break;
              case 'content_block_stop':
                // Handle content block stop event
                break;
              case 'message_delta':
                // Handle message delta event
                break;
              case 'message_stop':
                // Handle message stop event
                setStreamingResult(streamedData);
                break;
              case 'ping':
                // Handle ping event
                break;
              case 'error':
                // Handle error event
                console.error('SSE error:', data.error);
                break;
              default:
                console.warn('Unknown event type:', data.type);
            }
          };
    
          const handleError = (error: Event) => {
            console.error('SSE error:', error);
          };
    
          eventSource.addEventListener('message', handleMessage);
          eventSource.addEventListener('error', handleError);
    
          // Clean up the SSE connection when the component is unmounted
          return () => {
            eventSource.removeEventListener('message', handleMessage);
            eventSource.removeEventListener('error', handleError);
            eventSource.close();
          };
        } else {
          console.error('Error uploading screenshots:', response.statusText);
          // Handle the error case when uploading screenshots fails
        }
      } catch (error) {
        console.error('Error:', error);
        // Handle any errors that occur during the process
      } finally {
        setIsLoading(false);
      }
    }; */}

    {/* Display error message */}
    useEffect(() => {
      let timer: NodeJS.Timeout;
    
      if (showTooBigFile || showAuthError || showUploadFailed) {
        timer = setTimeout(() => {
          setShowTooBigFile(false);
          setShowAuthError(false);
          setShowUploadFailed(false);
        }, 7000); // 5000 milliseconds = 5 seconds
      }
    
      return () => {
        clearTimeout(timer);
      };
    }, [showTooBigFile, showAuthError, showUploadFailed]);
        
  

  return (
    <div>
      <aside className="lg:fixed lg:inset-y-0 lg:left-72 lg:w-96 overflow-y-auto border-r border-gray-200 px-4 py-6 sm:px-6 lg:px-8">
        <form>
          <div className="col-span-full mb-8">
            <h3 className="text-base font-semibold leading-6 text-gray-900">Upload screen or flow</h3>
            <p className="mt-2 max-w-4xl text-sm text-gray-500">
              You may add up to 5 images.
            </p>

            <div className="mt-4 flex flex-wrap gap-4">
              {uploadedImages.map((image, index) => (
                <div key={index} className="relative">
                  <img src={image} alt={`Preview ${index + 1}`} className="h-40 w-64 sm:w-full object-cover rounded-lg" />
                  <button
                    className="absolute top-2 right-2 bg-white rounded-full p-1 hover:bg-gray-100 focus:outline-none"
                    onClick={() => handleRemoveImage(index)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              ))}
              {uploadedImages.length < 6 && (
                <div className="h-40 w-full justify-center rounded-lg border border-dashed border-gray-900/25 flex items-center">
                  <div className="text-center">
                    <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                    <div className="mt-4 flex text-sm leading-6 text-gray-600 justify-center">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                      >
                        <span>Upload image</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          onChange={handleFileChange}
                          multiple
                        />
                      </label>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">PNG or JPG up to 5MB</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mt-8 flex justify-between items-center">
            <h3 className="text-base font-semibold leading-6 text-gray-900">Select goal</h3>
            <button
              type="button"
              className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
              onClick={() => setShowAllOptions(!showAllOptions)}
            >
              {showAllOptions ? 'Show less' : 'Show more'}
            </button>
          </div>
          <p className="mt-2 max-w-4xl text-sm text-gray-500 mb-4">
            Select what you want your designs to do.
          </p>

          <RadioGroup value={selectedMethodLists} onChange={setSelectedMethodLists}>
            <div className="space-y-4">
              {visibleOptions.map((methodList) => (
                <RadioGroup.Option
                  key={methodList.id}
                  value={methodList}
                  className={({ active }) =>
                    classNames(
                      active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
                      'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
                    )
                  }
                >
                  {({ active, checked }) => (
                    <>
                      <span className="flex flex-1">
                        <span className="flex flex-col">
                          <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                            {methodList.title}
                          </RadioGroup.Label>
                        </span>
                      </span>
                      <CheckCircleIcon
                        className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          active ? 'border' : 'border-2',
                          checked ? 'border-indigo-600' : 'border-transparent',
                          'pointer-events-none absolute -inset-px rounded-lg'
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>

          <div className="mt-8">
            <h3 className="text-base font-semibold leading-6 text-gray-900">Add details about your design</h3>
            <p className="mt-2 max-w-4xl text-sm text-gray-500">
              If you have any specific feedback or data that should guide the analysis, or a specific issue or goal in mind that you want to address, feel free to enter it here.
            </p>
            <div className="mt-4">
              <input
                type="text"
                name="userComment"
                id="userComment"
                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Optional"
                aria-describedby="userComment-description"
                value={userComment}
                onChange={(e) => setuserComment(e.target.value)}
              />
            </div>
          </div>

          <div className="sticky bottom-0 left-0 right-0 bg-white py-4">
      <div className="flex justify-center">
        <button
          type="submit"
          onClick={handleSubmit}
          className={`w-full max-w-xs flex items-center justify-center rounded-md bg-indigo-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
            !files || files.length === 0 || isLoading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={!files || files.length === 0 || isLoading}
        >
          <SparklesIcon className="h-5 w-5 mr-2" aria-hidden="true" />
          {isLoading ? 'Loading...' : 'Review my design'}
        </button>
      </div>
    </div>


        </form>
      </aside>
      

      <main className="lg:pl-72">
        <div className="lg:pl-96">
          <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6"> 
            <p>{streamingResult}</p>
          </div>
        </div>
      </main>
      <UpgradePopUp open={showUpgradePopUp} onClose={() => setShowUpgradePopUp(false)} />  
    </div>
    
  );
};

  export default GoalPage;