import { JSXElementConstructor, Key, ReactElement, ReactNode, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dialog, Switch } from '@headlessui/react'
import { Bars3Icon } from '@heroicons/react/20/solid'
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
  BellIcon,
  CreditCardIcon,
  CubeIcon,
  FingerPrintIcon,
  UserCircleIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { auth } from '../firebase'; // Import the Firebase auth instance
import axios from 'axios';

const stripePromise = loadStripe('pk_live_51P1OQS04pj69RFSAkqtf17RNNT1bXKytGBgLTeIrKHH5lNtWqkASpbbJmBN8GEc83FzYfYdCMeJQAkZxP8aVr3Vt00IOumeExQ');

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const ProfilePage: React.FC = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [userProfile, setUserProfile] = useState<{
      displayName: string;
      email: string;
      product: string;
    } | null>(null);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchUserProfile = async () => {
        const user = auth.currentUser;
        if (!user) {
          console.error('User not authenticated');
          return;
        }
  
        try {
          const idToken = await user.getIdToken(true);
          const userId = user.uid;
  
          const response = await axios.post(
            'https://us-central1-avery-59f28.cloudfunctions.net/getUserProfile',
            { userId },
            { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${idToken}` } }
          );
  
          setUserProfile(response.data);
          setLoading(false);
        } catch (error) {
          console.error('Error retrieving user profile:', error);
          setLoading(false);
        }
      };
  
      fetchUserProfile();
    }, []);


    const handleLogout = async () => {
      try {
        await auth.signOut();
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };


    const handleSubscriptionPurchase = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.error('User not authenticated');
        // TODO: Handle user not authenticated error
        return;
      }
  
      const userId = user.uid;
      console.log('User Id:', userId);
  
      try {
        const idToken = await user.getIdToken(true);
        const response = await axios.post(
          'https://us-central1-avery-59f28.cloudfunctions.net/createCheckoutSession',
          { userId },
          { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${idToken}` } }
        );
  
        const data = response.data;
        if (data.sessionUrl) {
          window.location.href = data.sessionUrl;
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (error) {
        console.error('Error creating Checkout Session:', error);
      }
    };
  
    const getTierText = (product: string | undefined) => {
      console.log('user data:', userProfile);

      return product?.includes('Design Review Premium') ? 'Premium' : 'Free';
    };

    const isFreeTier = userProfile?.product?.includes('Design Review Premium') !== true;


    return (
      <main className="lg:pl-72 flex justify-center min-h-screen pt-10">
        <div className="w-full max-w-4xl px-4 sm:px-6 lg:px-8">
          {loading ? (
            <div className="text-center text-gray-900">Loading profile...</div>
          ) : (
            <div className="py-10 lg:py-16">
              <div className="flex justify-between items-center">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Profile</h2>
                <button
                  onClick={handleLogout}
                  className="font-semibold text-red-600 hover:text-red-500"
                >
                  Log out
                </button>
              </div>
              {userProfile ? (
                <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Full name</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">{userProfile.displayName}</div>
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email address</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">{userProfile.email}</div>
                    </dd>
                  </div>
  
                  <div className="pt-12 sm:flex">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Subscription & Billing</h2>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Current Plan</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">{getTierText(userProfile.product)}</div>
                      {isFreeTier ? (
                        <button
                          onClick={handleSubscriptionPurchase}
                          type="button"
                          className="font-semibold text-indigo-600 hover:text-indigo-500"
                        >
                          Upgrade
                        </button>
                      ) : (
                        <button
                          onClick={handleSubscriptionPurchase}
                          type="button"
                          className="font-semibold text-indigo-600 hover:text-indigo-500"
                        >
                          Manage subscription
                        </button>
                      )}
                    </dd>
                  </div>
                </dl>
              ) : (
                <div className="text-gray-900">Loading user profile...</div>
              )}
            </div>
          )}
        </div>
      </main>
    );
  };
  

export default ProfilePage;
