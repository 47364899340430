import React from 'react';

const AIInterviewsPage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <h1 className="text-4xl font-bold mb-8">Welcome to theinterview</h1>
      <div className="max-w-lg mx-auto">
        <p className="text-lg mb-4">
          This is the start page of your application. You can add your content here.
        </p>
        <div className="bg-white shadow-md rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">Getting Started</h2>
          <ul className="list-disc pl-6">
            <li>Step 1: Lorem ipsum dolor sit amet</li>
            <li>Step 2: Consectetur adipiscing elit</li>
            <li>Step 3: Sed do eiusmod tempor incididunt</li>
          </ul>
        </div>
        <div className="mt-8">
          <button className="bg-indigo-600 text-white px-6 py-3 rounded-md font-semibold hover:bg-indigo-700">
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default AIInterviewsPage;