import React, { useState, useEffect } from 'react';
import { auth, analytics } from '../firebase';
import { PhotoIcon, CheckCircleIcon, SparklesIcon } from '@heroicons/react/24/solid';
import { RadioGroup } from '@headlessui/react';
import UpgradePopUp from './UpgradePopUp';
import axios from 'axios';

const methodLists = [
    { id: 1, title: 'Cognitive Walkthrough', description: 'Evaluate the learnability of your interface by assessing how easily users can understand and navigate it to complete tasks.', users: '621 users' },
    { id: 2, title: 'Heuristic Evaluation', description: 'Identify usability issues and improve your design based on established usability principles.', users: '1200 users' },
    { id: 3, title: 'Visual Hierarchy Assessment', description: 'Ensure that your most important information and call-to-action buttons stand out and are easily accessible.', users: '2740 users' },
    { id: 4, title: 'Emotional Design Analysis', description: 'Create a memorable and positive user experience by assessing the emotional impact of your design.', users: '2740 users' },
    { id: 5, title: 'Consistency Check', description: 'Maintain a cohesive and predictable user experience by ensuring consistency across your design elements.', users: '2740 users' },
    { id: 6, title: 'Accessibility Evaluation', description: 'Make your design inclusive and accessible to all users, regardless of their abilities.', users: '2740 users' },
];

interface MethodMapping {
    [key: string]: string;
}

const methodMapping: MethodMapping = {
    '1': 'method_a1',
    '2': 'method_a2',
    '3': 'method_a3',
    '4': 'method_a4',
    '5': 'method_a5',
    '6': 'method_a6',
};

function classNames(...classes: (string | boolean)[]) {
    return classes.filter(Boolean).join(' ');
}

const UXReviewPage: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState<FileList | null>(null);
    const [uploadedImages, setUploadedImages] = useState<string[]>([]);
    const [userComment, setuserComment] = useState('');
    const [userCredits, setUserCredits] = useState<number | null>(null);
    const [showUpgradePopUp, setShowUpgradePopUp] = useState(false);
    const [showTooBigFile, setShowTooBigFile] = useState(false);
    const [showUploadFailed, setShowUploadFailed] = useState(false);
    const [showAuthError, setShowAuthError] = useState(false);
    const [selectedMethodLists, setSelectedMethodLists] = useState(methodLists[0]);
    const [showAllOptions, setShowAllOptions] = useState(false);
    const visibleOptions = showAllOptions
        ? methodLists
        : methodLists.slice(0, window.innerWidth <= 640 ? 1 : 3);
    const [userId, setUserId] = useState<string>('');
    const [channelId, setChannelId] = useState<string>('');
    const [result, setResult] = useState<string | null>(null);
    const [delay, setDelay] = useState<number>(30000); // delay in milliseconds, default 30 seconds

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        analytics.logEvent('upload_page_addedImage', {
            button_name: 'upload_image',
        });
        const selectedFiles = event.target.files;
        if (selectedFiles) {
            const newImages = Array.from(selectedFiles)
                .map((file) => {
                    if (file.size > 10 * 1024 * 1024) {
                        setShowTooBigFile(true);
                        return null;
                    }
                    return URL.createObjectURL(file);
                })
                .filter((image): image is string => image !== null);

            if (uploadedImages.length + newImages.length <= 5) {
                setUploadedImages((prevImages) => {
                    const updatedImages = [...prevImages, ...newImages];
                    return updatedImages;
                });
                setFiles(selectedFiles);
            }
        }
    };

    const handleRemoveImage = (index: number) => {
        analytics.logEvent('upload_page_removeImage', {
            button_name: 'remove_image',
        });
        setUploadedImages((prevImages) => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });
        if (files) {
            const updatedFiles = new DataTransfer();
            Array.from(files)
                .filter((_, i) => i !== index)
                .forEach((file) => {
                    updatedFiles.items.add(file);
                });
            setFiles(updatedFiles.files);
        }
    };

    const fetchUserCredits = async () => {
        const user = auth.currentUser;
        if (!user) {
            console.error('User not authenticated');
            return null;
        }
        try {
            const idToken = await user.getIdToken(true);
            const userId = user.uid;
            console.log('Sending request to getUserCredits with userId:', userId);
            const response = await axios.post(
                'https://us-central1-avery-59f28.cloudfunctions.net/getUserCredits',
                { userId },
                { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${idToken}` } }
            );
            const credits = response.data.credits;
            console.log('User credits:', credits);
            setUserCredits(credits);
            return credits;
        } catch (error) {
            if (error instanceof Error) {
                console.error('Error retrieving user credits:', error.message);
            } else {
                console.error('An unknown error occurred:', error);
            }
            setUserCredits(0);
            return 0;
        }
    };

    const getLatestDesignReview = async (userId: string) => {
        try {
            console.log('Fetching latest design review...');
            const idToken = await auth.currentUser?.getIdToken(true);
            const response = await fetch('https://us-central1-avery-59f28.cloudfunctions.net/getLatestDesignReview', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userId }),
            });

            if (!response.ok) {
                throw new Error('Failed to retrieve latest design review');
            }

            const reviewData = await response.json();
            console.log('Latest design review data:', reviewData); // Log the full response data
            if (reviewData) {
                setResult(JSON.stringify(reviewData, null, 2));
            } else {
                console.warn('No review data found');
                setResult('No review data found');
            }
        } catch (error) {
            console.error('Error retrieving latest design review:', error);
            setShowUploadFailed(true);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const user = auth.currentUser;
        if (!user) {
            console.error('User not authenticated');
            setShowAuthError(true);
            setIsLoading(false);
            return;
        }

        const userCredits = await fetchUserCredits();
        if (userCredits === null || userCredits <= 0) {
            setShowUpgradePopUp(true);
            setIsLoading(false);
            return;
        }

        try {
            const idToken = await user.getIdToken(true);
            const userId = user.uid;
            const methodId = methodMapping[selectedMethodLists.id.toString()];

            console.log('Converting uploaded images to base64...');
            const screenshotPromises = uploadedImages.map((image) => {
                return new Promise<string>((resolve, reject) => {
                    fetch(image)
                        .then((response) => response.blob())
                        .then((blob) => {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                resolve(reader.result as string);
                            };
                            reader.onerror = reject;
                            reader.readAsDataURL(blob);
                        })
                        .catch(reject);
                });
            });

            const screenshots = await Promise.all(screenshotPromises);

            const requestData = {
                userId,
                methodId,
                screenshots,
                userComment,
            };

            console.log('Request Data:', requestData);
            console.log('Sending request to uploadScreenshot...');

            const response = await fetch('https://us-central1-avery-59f28.cloudfunctions.net/uploadScreenshot', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${idToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                throw new Error('Failed to upload screenshots');
            }

            console.log('Screenshots uploaded successfully');

            // Call getLatestDesignReview after a delay
            console.log(`Setting delay for ${delay / 1000} seconds to fetch latest design review...`);
            setTimeout(() => {
                console.log('Delay over, now fetching latest design review...');
                getLatestDesignReview(userId);
            }, delay);

        } catch (error) {
            console.error('Error uploading screenshots:', error);
            setShowUploadFailed(true);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (showTooBigFile || showAuthError || showUploadFailed) {
            timer = setTimeout(() => {
                setShowTooBigFile(false);
                setShowAuthError(false);
                setShowUploadFailed(false);
            }, 7000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [showTooBigFile, showAuthError, showUploadFailed]);

    const renderFormattedResult = () => {
        if (!result) {
            return null;
        }

        const parsedResult = JSON.parse(result);
        const formattedResult = `
            <div>
                <h1 class="text-2xl font-bold mb-4">${parsedResult.reviewName.replace(/"/g, '')}</h1>
                <h3 class="text-lg mb-4">${parsedResult.intro}</h3>
                <div class="mb-4">
                    <div class="flex items-center mb-1">
                        <div class="w-2 h-2 bg-green-500 rounded-full mr-2"></div>
                        <h5 class="text-base font-semibold">Strengths</h5>
                    </div>
                    <ul style="list-style-type: none; padding-left: 20px;">
                        ${parsedResult.pros.replace(/\d+\)/g, '•').replace(/\n/g, '</li><li>').replace(/^/, '<li>').replace(/$/, '</li>')}
                    </ul>
                </div>
                <div class="mb-4">
                    <div class="flex items-center mb-1">
                        <div class="w-2 h-2 bg-red-500 rounded-full mr-2"></div>
                        <h5 class="text-base font-semibold">Weaknesses</h5>
                    </div>
                    <ul style="list-style-type: none; padding-left: 20px;">
                        ${parsedResult.cons.replace(/\d+\)/g, '•').replace(/\n/g, '</li><li>').replace(/^/, '<li>').replace(/$/, '</li>')}
                    </ul>
                </div>
                <div class="mb-4">
                    <div class="flex items-center mb-1">
                        <div class="w-2 h-2 bg-yellow-500 rounded-full mr-2"></div>
                        <h5 class="text-base font-semibold">Recommendations</h5>
                    </div>
                    <ul style="list-style-type: none; padding-left: 20px;">
                        ${parsedResult.recommendations.replace(/\d+\)/g, '•').replace(/\n/g, '</li><li>').replace(/^/, '<li>').replace(/$/, '</li>')}
                    </ul>
                </div>
                <div class="mb-4">
                    <div class="flex items-center mb-1">
                        <div class="w-2 h-2 bg-blue-500 rounded-full mr-2"></div>
                        <h5 class="text-base font-semibold">Experiments to Try</h5>
                    </div>
                    <ul style="list-style-type: none; padding-left: 20px;">
                        ${parsedResult.experimentsToTry.replace(/\d+\)/g, '•').replace(/\n/g, '</li><li>').replace(/^/, '<li>').replace(/$/, '</li>')}
                    </ul>
                </div>
            </div>
        `.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>'); // Replace **text** with <strong>text</strong>

        return (
            <div className="typewriter" dangerouslySetInnerHTML={{ __html: formattedResult }} />
        );
    };

    return (
        <div>
            <aside className="lg:fixed lg:inset-y-0 lg:left-72 lg:w-96 overflow-y-auto border-r border-gray-200 px-4 py-6 sm:px-6 lg:px-8">
                <form>
                    <div className="col-span-full mb-8">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Upload screen or flow</h3>
                        <p className="mt-2 max-w-4xl text-sm text-gray-500">
                            You may add up to 5 images.
                        </p>

                        <div className="mt-4 flex flex-wrap gap-4">
                            {uploadedImages.map((image, index) => (
                                <div key={index} className="relative">
                                    <img src={image} alt={`Preview ${index + 1}`} className="h-40 w-64 sm:w-full object-cover rounded-lg" />
                                    <button
                                        className="absolute top-2 right-2 bg-white rounded-full p-1 hover:bg-gray-100 focus:outline-none"
                                        onClick={() => handleRemoveImage(index)}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-4 w-4 text-gray-600"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            ))}
                            {uploadedImages.length < 6 && (
                                <div className="h-40 w-full justify-center rounded-lg border border-dashed border-gray-900/25 flex items-center">
                                    <div className="text-center">
                                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                        <div className="mt-4 flex text-sm leading-6 text-gray-600 justify-center">
                                            <label
                                                htmlFor="file-upload"
                                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                            >
                                                <span>Upload image</span>
                                                <input
                                                    id="file-upload"
                                                    name="file-upload"
                                                    type="file"
                                                    className="sr-only"
                                                    onChange={handleFileChange}
                                                    multiple
                                                />
                                            </label>
                                        </div>
                                        <p className="text-xs leading-5 text-gray-600">PNG or JPG up to 5MB</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mt-8 flex justify-between items-center">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Select analytical framework</h3>
                        <button
                            type="button"
                            className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
                            onClick={() => setShowAllOptions(!showAllOptions)}
                        >
                            {showAllOptions ? 'Show less' : 'Show more'}
                        </button>
                    </div>
                    <p className="mt-2 max-w-4xl text-sm text-gray-500 mb-4">
                        Select how you want your designs to be analyzed.
                    </p>

                    <RadioGroup value={selectedMethodLists} onChange={setSelectedMethodLists}>
                        <div className="space-y-4">
                            {visibleOptions.map((methodList) => (
                                <RadioGroup.Option
                                    key={methodList.id}
                                    value={methodList}
                                    className={({ active }) =>
                                        classNames(
                                            active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300',
                                            'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
                                        )
                                    }
                                >
                                    {({ active, checked }) => (
                                        <>
                                            <span className="flex flex-1">
                                                <span className="flex flex-col">
                                                    <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                                                        {methodList.title}
                                                    </RadioGroup.Label>
                                                </span>
                                            </span>
                                            <CheckCircleIcon
                                                className={classNames(!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600')}
                                                aria-hidden="true"
                                            />
                                            <span
                                                className={classNames(
                                                    active ? 'border' : 'border-2',
                                                    checked ? 'border-indigo-600' : 'border-transparent',
                                                    'pointer-events-none absolute -inset-px rounded-lg'
                                                )}
                                                aria-hidden="true"
                                            />
                                        </>
                                    )}
                                </RadioGroup.Option>
                            ))}
                        </div>
                    </RadioGroup>

                    <div className="mt-8">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Add details about your design</h3>
                        <p className="mt-2 max-w-4xl text-sm text-gray-500">
                            If you have any specific feedback or data that should guide the analysis, or a specific issue or goal in mind that you want to address, feel free to enter it here.
                        </p>
                        <div className="mt-4">
                            <input
                                type="text"
                                name="userComment"
                                id="userComment"
                                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Optional"
                                aria-describedby="userComment-description"
                                value={userComment}
                                onChange={(e) => setuserComment(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className="sticky bottom-0 left-0 right-0 bg-white py-4">
                        <div className="flex justify-center">
                            <button
                                type="submit"
                                onClick={handleSubmit}
                                className={`w-full max-w-xs flex items-center justify-center rounded-md bg-indigo-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ${
                                    !files || files.length === 0 || isLoading ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                                disabled={!files || files.length === 0 || isLoading}
                            >
                                <SparklesIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                                {isLoading ? 'Loading...' : 'Review my design'}
                            </button>
                        </div>
                    </div>
                </form>
            </aside>

            <main className="lg:pl-72">
                <div className="lg:pl-96">
                    <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6">
                        {renderFormattedResult()}
                    </div>
                </div>
            </main>
            <UpgradePopUp open={showUpgradePopUp} onClose={() => setShowUpgradePopUp(false)} />
        </div>
    );
};

export default UXReviewPage;
