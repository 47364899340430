import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'

const TermsPage: React.FC = () => {
    return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-indigo-600">Effective date: April 11, 2024</p>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Terms of Service for designreview.ai</h1>
        <p className="mt-6 text-xl leading-8">
        These Terms of Service ("Terms") govern your access to and use of the Design Review AI application and related services (collectively, the "Services") provided by Peachy Innovation AB ("we," "us," or "our"). By accessing or using our Services, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use our Services.
</p>
<div className="mt-10 max-w-2xl">
<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">1. Eligibility</h2>
<p className="mt-6">
You must be at least 13 years old to use our Services. By accessing or using our Services, you represent and warrant that you are at least 13 years old and have the legal capacity to enter into these Terms.</p>
</div>
        <div className="mt-16 max-w-2xl">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">2. User Accounts</h2>
<p className="mt-6">
2.1 To access certain features of our Services, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information as necessary.
</p>
<p className="mt-8">
2.2 You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized access to or use of your account.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">3. Intellectual Property</h2>
<p className="mt-6">
3.1 The Services and all content and materials included therein, such as text, graphics, logos, images, and software, are the property of Peachy Innovation AB or its licensors and are protected by intellectual property laws.
</p>
<p className="mt-8">
3.2 You may not reproduce, modify, distribute, or create derivative works based on the Services or any part thereof without our prior written consent.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">4. User Content</h2>
<p className="mt-6">
4.1 By uploading, submitting, or otherwise providing content (including screenshots and design files) through the Services, you grant us a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content for the purpose of providing and improving the Services.
</p>
<p className="mt-8">
4.2 You represent and warrant that you have all necessary rights and permissions to grant the license above and that your content does not infringe upon the intellectual property rights of any third party.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">5. Prohibited Conduct</h2>
<p className="mt-6">
You agree not to:
</p>
<ul className="mt-8 list-disc space-y-2 pl-4">
<li>Use the Services for any unlawful purpose or in violation of any applicable laws or regulations;</li>
<li>Upload or transmit any content that is defamatory, obscene, offensive, or infringes upon the rights of others;</li>
<li>Interfere with or disrupt the integrity or performance of the Services or any related systems or networks;</li>
<li>Attempt to gain unauthorized access to the Services or any related systems or networks;</li>
<li>Use any automated means, such as scripts or bots, to access or use the Services without our express written permission.</li>
</ul>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">6. Termination</h2>
<p className="mt-6">
We reserve the right to suspend or terminate your access to the Services at any time, with or without cause, and with or without notice. Upon termination, your right to use the Services will immediately cease.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">7. Disclaimer of Warranties</h2>
<p className="mt-6">
The Services are provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, as to the operation of the Services or the information, content, materials, or products included therein. You express ly agree that your use of the Services is at your sole risk.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">8. Limitation of Liability</h2>
<p className="mt-6">
To the fullest extent permitted by applicable law, Peachy Innovation AB and its affiliates, officers, employees, agents, partners, and licensors shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, use, data, or other intangible losses, resulting from your use of or inability to use the Services.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">9. Indemnification</h2>
<p className="mt-6">
You agree to indemnify, defend, and hold harmless Peachy Innovation AB and its affiliates, officers, employees, agents, partners, and licensors from any claims, liabilities, damages, losses, costs, or expenses, including reasonable attorneys' fees, arising out of or in connection with your use of the Services or your violation of these Terms.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">10. Modifications</h2>
<p className="mt-6">
We reserve the right to modify or replace these Terms at any time. If we make material changes to these Terms, we will notify you by posting the updated Terms on this page and updating the "Effective Date" at the top of the Terms. Your continued use of the Services after any modifications constitutes your acceptance of the updated Terms.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">11. Governing Law and Jurisdiction</h2>
<p className="mt-6">
These Terms shall be governed by and construed in accordance with the laws of Sweden, without regard to its conflict of law provisions. Any legal action or proceeding arising out of or relating to these Terms shall be brought exclusively in the courts of Sweden, and you consent to the jurisdiction of such courts.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">12. Entire Agreement</h2>
<p className="mt-6">
These Terms, together with our Privacy Policy, constitute the entire agreement between you and Peachy Innovation AB regarding the use of the Services and supersede all prior agreements and understandings, whether written or oral.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">13. Contact Us</h2>
<p className="mt-6">
If you have any questions, concerns, or feedback regarding these Terms, please contact us at:
</p>
<p className="mt-8">
Peachy Innovation AB<br />
Stockholm, Sweden<br />
Email: contact@peachyinnovation.com
</p>
</div>
<p className="mt-16">
By using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
</p>
</div>
</div>
)
}


export default TermsPage;