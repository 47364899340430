import { useState } from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { functions, auth, analytics } from '../firebase';
import axios from 'axios';

const HelpPage = () => {
  const [formData, setFormData] = useState({
    userId: '', // Populate with the authenticated user's ID
    feedback: '',
    context: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    try {
      const response = await axios.post('/submitFeedback', formData);

      if (response.status === 200) {
        setIsSubmitted(true);
        setFormData({ userId: '', feedback: '', context: '' });
      } else {
        console.error('Error submitting feedback:', response.data);
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  return (
    <div className="flex justify-center">
      <div className="max-w-3xl w-full px-4">
        {isSubmitted ? (
          <div className="text-center py-8">
            <CheckIcon className="h-12 w-12 text-green-500 mx-auto mb-4" />
            <p className="text-xl font-semibold">Thank you for reaching out - we'll get back to you soon.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900">Support</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  If you need help or have any feedback, please share your thoughts below. We'll respond within 24 hours.
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-4">
                    <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900">
                      Your email
                    </label>
                    <div className="mt-2">
                      <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <input
                          type="text"
                          name="username"
                          id="username"
                          autoComplete="username"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                          placeholder="johnsmith@gmail.com"
                          value={formData.userId}
                          onChange={handleChange}
                          />
                          </div>
                          </div>
                          </div>

                          <div className="col-span-full">
                <label htmlFor="feedback" className="block text-sm font-medium leading-6 text-gray-900">
                  What do you need help with?
                </label>
                <div className="mt-2">
                  <textarea
                    id="feedback"
                    name="feedback"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={formData.feedback}
                    onChange={handleChange}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Submit
          </button>
        </div>
      </form>
    )}
  </div>
</div>
);
};
export default HelpPage;
