import { Fragment, useEffect, useState } from 'react';
import axios from 'axios';
import { auth } from '../firebase';
import { analytics } from '../firebase';
import HistoryReviewPage from './HistoryReviewPage'; // Import the HistoryReviewPage component

type DesignReview = {
  channelId: string;
  reviewName: string;
  lastResponseTimestamp: string | null;
  overallPros: string;
  overallCons: string;
  overallRecommendations: string;
  screenshotDetails: ScreenshotDetails[];
  screenshots: string[];
  pros: string;
  cons: string;
  recommendations: string;
};

type ScreenshotDetails = {
  pros: string | string[];
  cons: string | string[];
  recommendations: string | string[];
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type HistoryPageProps = {
  setCurrentPage: (page: string) => void;
  setSelectedReviewId: (reviewId: string | null) => void;
};

const NewHistoryPage: React.FC<HistoryPageProps> = ({ setCurrentPage, setSelectedReviewId }) => {
  const [designReviews, setDesignReviews] = useState<DesignReview[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedChannelId, setSelectedChannelId] = useState<string | null>(null);

  useEffect(() => {
    analytics.logEvent('history_page_viewed');
  }, []);

  const handleOpenReview = (channelId: string) => {
    setSelectedChannelId(channelId);
    setSelectedReviewId(channelId);
  };

  useEffect(() => {
    const fetchDesignReviews = async () => {
      console.log('Fetching design reviews...');
      try {
        const user = auth.currentUser;
        if (!user) {
          console.error('User not authenticated');
          return;
        }
        const idToken = await user.getIdToken(true);
        const userId = user.uid;
        const response = await axios.post(
          'https://us-central1-avery-59f28.cloudfunctions.net/getDesignReviewList',
          { userId: userId },
          { headers: { Authorization: `Bearer ${idToken}` } }
        );
        setDesignReviews(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching design reviews:', error);
        setIsLoading(false);
      }
    };
    fetchDesignReviews();
  }, []);

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen pb-32 lg:pl-72">
        <p className="text-sm text-gray-500">Loading previous reviews...</p>
      </div>
    );
  }

  if (designReviews.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen pb-32 lg:pl-72">
        <h3 className="text-sm font-semibold text-gray-900">No reviews</h3>
        <p className="mt-1 text-sm text-gray-500">You'll find your reviews here.</p>
      </div>
    );
  }

  return (
    <div className="flex">
      <aside className="lg:fixed lg:inset-y-0 lg:left-72 lg:w-96 overflow-y-auto border-r border-gray-200 px-4 py-6 sm:px-6 lg:px-8">
        <ul role="list" className="divide-y divide-gray-100">
          {designReviews.map((review) => (
            <li key={review.channelId} className="flex items-center justify-between gap-x-6 py-5">
              <div className="min-w-0">
                <div className="flex items-start gap-x-3">
                  <button
                    onClick={() => handleOpenReview(review.channelId)}
                    className="text-left"
                  >
                    <p className="text-sm font-semibold leading-6 text-gray-900 text-left">{review.reviewName}</p>
                  </button>
                </div>
                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                  <p className="whitespace-nowrap">
                    Created on{' '}
                    <time dateTime={review.lastResponseTimestamp || ''}>
                      {review.lastResponseTimestamp ? new Date(review.lastResponseTimestamp).toLocaleDateString() : 'N/A'}
                    </time>
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </aside>

      <main className="lg:pl-72 flex-1">
        <div className="lg:pl-96">
          {selectedChannelId ? (
            <HistoryReviewPage channelId={selectedChannelId} />
          ) : (
            <div className="flex flex-col items-center justify-center min-h-screen">
              <h3 className="text-sm font-semibold text-gray-900">Select a review</h3>
              <p className="mt-1 text-sm text-gray-500">Click on a review on the left to see its details.</p>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default NewHistoryPage;
