import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid'

const PrivacyPolicyPage: React.FC = () => {
    return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
        <p className="text-base font-semibold leading-7 text-indigo-600">Effective date: April 11, 2024</p>
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Privacy Policy for designreview.ai</h1>
        <p className="mt-6 text-xl leading-8">
At Peachy Innovation AB ("we," "us," or "our"), we are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our Design Review AI application and related services (collectively, the "Services"). By accessing or using our Services, you agree to the terms of this Privacy Policy.
</p>
<div className="mt-10 max-w-2xl">
<h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Information We Collect</h2>
<p className="mt-6">
1.1 Personal Information: When you create an account or use our Services, we may collect personal information such as your name, email address, and company name. We collect this information to provide and improve our Services, communicate with you, and personalize your experience.
</p>
<p className="mt-8">
1.2 Design Data: When you upload screenshots or design files for review, we collect and store those files on our servers. We use this data solely for the purpose of providing design review and analysis services.
</p>
<p className="mt-8">
1.3 Usage Information: We may collect information about how you interact with our Services, including your IP address, browser type, device information, and pages visited. This information is collected using cookies and similar technologies and helps us analyze trends, administer the Services, and gather demographic information.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">Use of Information</h2>
<p className="mt-6">
2.1 We use the information we collect to provide, maintain, and improve our Services, develop new features, and communicate with you.
</p>
<p className="mt-8">
2.2 We may use your email address to send you updates, notifications, and marketing communications related to our Services. You can opt-out of receiving marketing emails at any time by following the unsubscribe instructions provided in the email.
</p>
<p className="mt-8">
2.3 We may use aggregated and anonymized data for statistical analysis, research, and business purposes, such as improving our AI algorithms and enhancing the user experience.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">Information Sharing and Disclosure</h2>
<p className="mt-6">
3.1 We do not sell, trade, or rent your personal information to third parties for marketing purposes.
</p>
<p className="mt-8">
3.2 We may share your information with trusted third-party service providers who assist us in operating our Services, conducting our business, or providing services to you. These service providers are bound by confidentiality obligations and are only permitted to use your information as necessary to perform services on our behalf.
</p>
<p className="mt-8">
            3.3 We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).
</p>
<p className="mt-8">
3.4 In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction. We will notify you via email and/or a prominent notice on our website of any change in ownership or use of your personal information.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">Data Security</h2>
<p className="mt-6">
We implement reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">Data Retention</h2>
<p className="mt-6">
We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">Your Rights</h2>
<p className="mt-6">
6.1 You have the right to access, update, or delete your personal information. You can exercise these rights by logging into your account or contacting us at contact@peachyinnovation.com.
</p>
<p className="mt-8">
6.2 If you are a resident of the European Economic Area (EEA), you have certain additional rights under the General Data Protection Regulation (GDPR), such as the right to data portability and the right to restrict or object to the processing of your personal information.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">Children's Privacy</h2>
<p className="mt-6">
Our Services are not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">Changes to this Privacy Policy</h2>
<p className="mt-6">
We reserve the right to update or modify this Privacy Policy at any time. We will notify you of any changes by posting the updated Privacy Policy on this page and updating the "Effective Date" at the top of the policy. Your continued use of our Services after any modifications constitute your acceptance of the updated Privacy Policy.
</p>
</div>
<div className="mt-16 max-w-2xl">
<h2 className="text-2xl font-bold tracking-tight text-gray-900">Contact Us</h2>
<p className="mt-6">
If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at:
</p>
<p className="mt-8">
Peachy Innovation AB<br />
Stockholm, Sweden<br />
Email: contact@peachyinnovation.com
</p>
</div>
<p className="mt-16">
By using our Services, you acknowledge that you have read and understood this Privacy Policy and agree to be bound by its terms and conditions.
</p>
</div>
</div>
)
}

export default PrivacyPolicyPage;