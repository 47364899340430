import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../firebase';
import { Dialog, Transition } from '@headlessui/react'
import ResultPage from '../components/ResultPage';
import UXReviewPage from '../components/UXReviewPage';
import StartPage from '../components/StartPage';
import GoalPage from '../components/GoalPage'; 
import AIInterviewsPage from '../components/AIInterviewsPage'; 
import ProfilePage from '../components/ProfilePage';
import PaywallPage from '../components/PaywallPage';
import NewHistoryPage from '../components/NewHistoryPage';
import HelpPage from '../components/HelpPage';
import UpgradeSuccessPopover from '../components/UpgradeSuccess';
import UpgradeFailPopover from '../components/UpgradeFailed';
import logo from '../assets/designreviewlogo.png';
import axios from 'axios';
import {
  Bars3Icon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  ChartBarSquareIcon,
  BeakerIcon
} from '@heroicons/react/24/outline'



const AppPage: React.FC = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState('uxReview');
  const [showSuccessPopover, setShowSuccessPopover] = useState(false);
  const [showFailPopover, setShowFailPopover] = useState(false);
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [userProfile, setUserProfile] = useState<any>(null);
  const [selectedReviewId, setSelectedReviewId] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.error('User not authenticated');
        return;
      }

      try {
        console.log('Fetching user profile...');
        const idToken = await user.getIdToken(true);
        const userId = user.uid;

        const response = await axios.post(
          'https://us-central1-avery-59f28.cloudfunctions.net/getUserProfile',
          { userId },
          { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${idToken}` } }
        );

        setUserProfile(response.data);
        console.log('User Profile:', response.data); 
      } catch (error) {
        console.error('Error retrieving user profile:', error);
      }
    };

    fetchUserProfile();
  }, [auth.currentUser]);

   {/* { name: 'Start', icon: HomeIcon, current: currentPage === 'start', page: 'start' },*/}


  const navigation = [
    { name: 'UX Review', icon: BeakerIcon, current: currentPage === 'uxReview', page: 'uxReview' },
    { name: 'Goal-based Analysis', icon: ChartBarSquareIcon, current: currentPage === 'goalAnalysis', page: 'goalAnalysis' },
    { name: 'History', icon: DocumentDuplicateIcon, current: currentPage === 'history', page: 'history' },
  ];

  {/*{ name: 'AI Interviews', icon: CalendarIcon, current: false, page: 'aiInterviews' },*/}


  const teams = [
    { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
    { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
    { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
  ]

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is authenticated
        console.log('User is authenticated:', user);
        // Perform any necessary actions or navigations
      } else {
        // User is not authenticated
        console.log('User is not authenticated');
        // Handle the unauthenticated state, such as redirecting to the login page
      }
    });
  
    // Clean up the observer when the component unmounts
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const showSuccessPopoverParam = searchParams.get('upgradeSuccess');
    const showFailedPopoverParam = searchParams.get('upgradeFailed');
    if (showSuccessPopoverParam === 'true') {
      setShowSuccessPopover(true);
    }
    if (showFailedPopoverParam === 'true') {
      setShowFailPopover(true);
    }
  }, [location.search]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate('/');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const handleCloseSuccessPopover = () => {
    setShowSuccessPopover(false);
  };

  const handleCloseFailedPopover = () => {
    setShowFailPopover(false);
  };

  const renderPage = () => {
    switch (currentPage) {
      case 'start':
        return <StartPage />;
      case 'uxReview':
        return <UXReviewPage />;
      case 'goalAnalysis':
        return <GoalPage />;
      case 'aiInterviews':
        return <AIInterviewsPage />;
      case 'history':
        return (
          <NewHistoryPage
            setCurrentPage={setCurrentPage}
            setSelectedReviewId={setSelectedReviewId}
          />
        );
        return <PaywallPage />;
      case 'help':
        return <HelpPage />;
      case 'profile':
        return <ProfilePage />;
      default:
        return null;
    }
  };


  return (
    <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src={logo}
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
  <ul role="list" className="-mx-2 space-y-1">
    {navigation.map((item) => (
      <li key={item.name}>
        <a
          onClick={() => {
            setCurrentPage(item.page);
            setSidebarOpen(false);
          }}
          className={classNames(
            currentPage === item.page
              ? 'bg-gray-50 text-indigo-600'
              : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
          )}
        >
          <item.icon
            className={classNames(
              currentPage === item.page ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
              'h-6 w-6 shrink-0'
            )}
            aria-hidden="true"
          />
          {item.name}
        </a>
      </li>
    ))}
  </ul>
</li>
                       {/* <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <a
                                  href={team.href}
                                  className={classNames(
                                    team.current
                                      ? 'bg-gray-50 text-indigo-600'
                                      : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <span
                                    className={classNames(
                                      team.current
                                        ? 'text-indigo-600 border-indigo-600'
                                        : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
                                      'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                    )}
                                  >
                                    {team.initial}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li> */}
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-8 w-auto"
                src={logo}
                alt="Your Company"
              />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
  <ul role="list" className="-mx-2 space-y-1">
    {navigation.map((item) => (
      <li key={item.name}>
        <button
          onClick={() => setCurrentPage(item.page)}
          className={classNames(
            currentPage === item.page
              ? 'bg-gray-50 text-indigo-600'
              : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
          )}
        >
          <item.icon
            className={classNames(
              currentPage === item.page ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
              'h-6 w-6 shrink-0'
            )}
            aria-hidden="true"
          />
          {item.name}
        </button>
      </li>
    ))}
  </ul>
</li>
                {/*<li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? 'bg-gray-50 text-indigo-600'
                              : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <span
                            className={classNames(
                              team.current
                                ? 'text-indigo-600 border-indigo-600'
                                : 'text-gray-400 border-gray-200 group-hover:border-indigo-600 group-hover:text-indigo-600',
                              'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                            )}
                          >
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li> */}

<li className="-mx-6 mt-auto">
  <a
     onClick={() => setCurrentPage('profile')}
    className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
  >
    {userProfile && userProfile.photoURL ? (
      <>
        <img
          className="h-8 w-8 rounded-full bg-gray-50"
          src={userProfile.photoURL}
          alt=""
        />
        <span className="sr-only">Your profile</span>
        <span aria-hidden="true">{userProfile.displayName}</span>
      </>
    ) : (
      <div className="h-8 w-8 rounded-full bg-[#5046E5]" />
    )}
  </a>
</li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
  <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
    <span className="sr-only">Open sidebar</span>
    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
  </button>
  <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">Dashboard</div>
  <a onClick={() => setCurrentPage('profile')}>
    <span className="sr-only">Your profile</span>
    {userProfile && userProfile.photoURL ? (
      <img
        className="h-8 w-8 rounded-full bg-gray-50"
        src={userProfile.photoURL}
        alt=""
      />
    ) : (
      <div className="h-8 w-8 rounded-full bg-[#5046E5]" />
    )}
  </a>
</div>


        {renderPage()}

     
        <UpgradeSuccessPopover open={showSuccessPopover} onClose={handleCloseSuccessPopover} />
      <UpgradeFailPopover open={showFailPopover} onClose={handleCloseFailedPopover} />
      </div>
    

  );
};

export default AppPage; 






  {/*  <aside className="lg:fixed lg:inset-y-0 lg:left-72 lg:w-96 overflow-y-auto border-r border-gray-200 px-4 py-6 sm:px-6 lg:px-8">
          Secondary column
          {renderPage()}
          <p>side bar</p>

        </aside>

     

     <main className="lg:pl-72">
          <div className="lg:pl-96">
            <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-6"> 
                

                <p>main area</p>
            
            
            
            </div>
          </div>
          
        </main> */}