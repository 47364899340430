import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import 'firebase/compat/analytics';



const firebaseConfig = {
  apiKey: "AIzaSyDv_81w2KfqG7rD6lN65LMPYGafK__QEsc",
  authDomain: "designreview.ai",
  projectId: "avery-59f28",
  storageBucket: "avery-59f28.appspot.com",
  messagingSenderId: "730066950630",
  appId: "1:730066950630:web:f2be169157dad13cfb9e27",
  measurementId: "G-W4B6TPN10H"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const functions = firebase.functions();
const storage = firebase.storage();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const analytics = firebase.analytics();

export { auth, functions, storage, analytics, googleAuthProvider };